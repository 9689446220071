.sidebar {
    background   : $white;
    position     : fixed;
    left         : 0px;
    height       : calc(100%-20px);
    width        : 85px;
    top          : 0px;
    z-index      : 3;
    bottom       : 0px;
    border-radius: 0px;
    border-right : 1px solid $border-color;

    @include respond('phone-land') {
        top          : auto;
        bottom       : 0;
        width        : 100%;
        height       : 50px;
        left         : 0;
        border-radius: 0px;
    }
}

.brand-logo {
    text-align: center;
    margin-top: 30px;

    img {
        // max-width: 35px;
        // filter   : brightness(100);
    }

    @include respond('phone-land') {
        display: none;

    }
}

.menu {
    margin-top: 100px;

    @include respond('phone-land') {
        margin-top: 0px;
    }

    ul {
        @include respond('phone-land') {
            display        : flex;
            justify-content: space-around;
            align-items    : center;
        }

        li {
            text-align: center;

            a {
                padding      : 12px 15px;
                display      : inline-block;
                margin-bottom: 20px;
                border-radius: $radius + 5px;

                &:hover,
                &:focus,
                &:active {
                //    background: lighten($dark, 0%);
                    color  : $dark;
                    opacity: 1;
                    i {
                        color  : $dark;
                        opacity: 1;
                    }
                }

                &.active {
                    // background: lighten($dark, 0%);

                    i {
                        color  : $dark;
                        opacity: 1;
                    }
                }

                i {
                    color    : $body-color;
                    font-size: 24px;
                    // opacity  : 0.5;
                }
            }

            &.logout {
                position: absolute;
                bottom  : 50px;
                left    : 0;
                right   : 0;

                @include respond('phone-land') {
                    position: relative;
                    bottom  : 0;
                    left    : auto;
                    right   : auto;

                }
            }
        }
    }
}

.copyright {
    position  : absolute;
    bottom    : 0;
    left      : 0;
    right     : 0;
    text-align: center;
    font-size : 14px;

    color: $white;

    a {
        color    : $white;
        font-size: 12px;
    }

    @include respond('phone-land') {
        display: none;

    }
}