.welcome-profile {
    background: $primary !important;

    .card-body {
        img {
            border       : 4px solid #fff;
            border-radius: 100px;
            width        : 60px;
        }

        // background   : $primary !important;
        // text-align: center;

        h4 {
            color     : $white;
            margin-top: 10px;
            font-size : 18px;
        }

        p {
            color          : $white;
            opacity        : 0.75;
            // max-width   : 300px;
            // margin   : 0 auto;

        }

        ul {
            text-align: left;

            li {
                padding      : 15px 0px;
                border-bottom: 1px solid rgba($white, 0.15%);

                &:last-child {
                    border        : 0px;
                    padding-bottom: 0px;
                }

                a {
                    color      : darken($white, 5%);
                    display    : flex;
                    align-items: center;
                    transition : all 0.25 ease-in-out;

                    span {
                        background     : $white;
                        padding        : 5px;
                        border-radius  : 50px;
                        width          : 30px;
                        height         : 30px;
                        display        : inline-flex;
                        align-items    : center;
                        justify-content: center;
                        margin-right   : 10px;
                        transition     : all 0.25 ease-in-out;

                        &.verified {
                            color: $success;
                        }

                        &.not-verified {
                            color: $danger;
                        }
                    }
                }
            }
        }
    }
}


.trade-form {
    p {
        font-size: 14px;
        color    : $headings-color;

        a {
            color: $primary;
        }
    }
}



.balance-chart {
    h4 {
        position  : absolute;
        bottom    : 0;
        left      : 0;
        right     : 0;
        text-align: center;
    }

    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.balance-widget {

    @include respond('tab-port') {
        margin-top: 30px;
    }

    li {
        display              : flex;
        justify-content      : space-between;
        // padding        : 18px;
        padding-bottom       : 15px;
        align-items          : center;
        border-bottom        : 1px solid $border-color;
        // background     : rgba(0, 0, 0, 0.15);
        // border-radius     : 15px;
        margin-bottom        : 15px;

        &:last-child {
            margin-bottom : 0px;
            padding-bottom: 0px;
            border-bottom : 0px;
        }

        .icon-title {
            display    : flex;
            align-items: center;

            i {
                font-size   : 30px;
                margin-right: 10px;
            }

            span {
                font-weight: 600;
                color      : $headings-color;
                font-size  : 16px;

                small {
                    opacity    : 0.75;
                    font-weight: normal;
                    font-size  : 14px;
                }
            }
        }

    }
}


.invite-content {
    // .btn {
    //     font-size: 15px;
    // }

    .input-group-text {
        background: $primary;
        color     : $white;
    }

    .social-share-link {
        margin-top: 15px;

        a {
            display     : inline-block;
            margin-right: 10px;

            i {
                font-size: 20px;

                &.icofont-facebook {
                    color: #3b5998;
                }

                &.icofont-twitter {
                    color: #1da1f2;
                }

                &.icofont-whatsapp {
                    color: #25d366;
                }

                &.icofont-telegram {
                    color: #0088cc;
                }
            }
        }
    }
}



.price-carousel {
    .price-widget {
        background      : #fff;
        // height       : 240px;
        border-radius   : 10px;
        margin          : 0px 15px;
        margin-bottom   : 30px;
    }

    button {
        background: $primary;
        width     : 30px;
        height    : 30px;

        &.slick-next:before {
            content    : "\eab8";
            font-family: "IcoFont";
        }

        &.slick-prev:before {
            font-family: "IcoFont";
            content    : "\eab5";
        }

        &:before {
            font-size: 24px;

        }

        &:hover,
        &:focus,
        &.active {
            background: $primary;
        }


    }
}



.price-widget {
    background   : #fff;
    height       : 120px;
    border-radius: 10px;
    margin       : 0px 0px 30px;
    // border       : 1px solid $border-color;
    box-shadow   : $shadow;
    overflow     : hidden;
    display      : flex;
    align-items  : center;

    a {
        display        : inline-flex;
        color          : $dark;
        align-items    : center;
        justify-content: space-between;
    }
}

.price-content {

    // position       : absolute;
    // display        : flex;
    // justify-content: space-between;
    // align-items    : center;
    padding           : 10px;
    // width          : 100%;

    .icon-title {
        display           : flex;
        // justify-content: space-between;
        align-items       : center;
    }



    i {
        // background   : #fff;
        padding       : 5px 7px;
        font-size     : 24px;
        // color      : #fff;
        line-height   : 40px;
    }

    span {
        color      : $dark;
        font-size  : 18px;
        font-weight: $headings-font-weight;
    }

    h5 {
        // margin-top          : 0px;
        color                  : $body-color;
        margin-left            : 10px;
        // opacity             : 0.85;
        // font-weight      : normal;
        font-size              : 14px;
    }
}



.home-chart {
    .form-select {
        width           : 150px;
        height          : auto;
        border          : 0px;
        padding         : 0px;
        background-color: transparent;
    }
}

.chart-price-value {
    text-align   : center;
    background   : $white;
    padding      : 15px;
    border-radius: 10px;

    span {
        display      : inline-block;
        margin-bottom: 5px;
        font-size    : 14px;
    }

    h5 {
        margin: 0px;
    }
}

.danger-arrow {
    display    : flex;
    align-items: center;

    i {
        color          : $danger;
        text-align     : center;
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin-right   : 10px;
        font-size      : 22px;
    }

    span.bt-content {
        display: inherit;
    }
}

.success-arrow {
    display    : flex;
    align-items: center;

    i {

        color          : $success;
        text-align     : center;
        display        : flex;
        justify-content: center;
        align-items    : center;
        margin-right   : 10px;
        font-size      : 22px;
    }

    .bt-content {
        display: inherit;
    }
}

.coin-name {
    i {
        font-size   : 20px;
        margin-right: 5px;
    }
}