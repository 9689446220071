/*
    Name: Tende
    Author: Qkit_
    Author Portfolio : https://themeforest.net/user/qkit_/portfolio
    Email: qkit.inc@gmail.com
    Skype: sporsho9 


    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    6. Pages
    
*/

@import "./abstract/abstract";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "./base/base";
@import "./layout/layout";
@import "./element/element";
@import "./components/component";
@import "./pages/pages";