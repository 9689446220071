/*
    Name: Tende
    Author: Qkit_
    Author Portfolio : https://themeforest.net/user/qkit_/portfolio
    Email: qkit.inc@gmail.com
    Skype: sporsho9 


    Table of Content: 

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Component
    6. Pages
    
*/
/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url(../vendor/perfect-scrollbar/perfect-scrollbar.css);
@import url(../vendor/basic-table/basictable.css);
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;600;700&display=swap");
@import url("../icons/font-awesome/css/all.css");
@import url("../icons/icofont/icofont.min.css");
@import url("../icons/bootstrap-icons/bootstrap-icons.css");
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/cryptocoins/css/cryptocoins.css");
@import url("../icons/cryptocoins/css/cryptocoins-colors.css");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6672e8;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #613de6;
  --bs-secondary: #eff2f7;
  --bs-success: #36b37e;
  --bs-info: #00b8d9;
  --bs-warning: #ffab00;
  --bs-danger: #ff5630;
  --bs-light: #f6f9fc;
  --bs-dark: #1e2e50;
  --bs-btc: #f7931a;
  --bs-eth: #282828;
  --bs-usdt: #2ca07a;
  --bs-xrp: #346aa9;
  --bs-ltc: #838383;
  --bs-ada: #3cc8c8;
  --bs-eos: #19191a;
  --bs-xmr: #ff6600;
  --bs-xtz: #a6df00;
  --bs-xem: #41bf76;
  --bs-neo: #58bf00;
  --bs-dash: #1c75bc;
  --bs-etc: #669073;
  --bs-dgb: #0066cc;
  --bs-dcr: #3b7cfb;
  --bs-rep: #40a2cb;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)); }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: "Spartan", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7184ad;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.6;
  color: #1f2c73; }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.875rem; } }

h3, .h3 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.5rem; } }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: 1rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #613de6;
  text-decoration: underline; }
  a:hover {
    color: #4e31b8; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #7184ad;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus {
  outline: dotted 1px;
  outline: -webkit-focus-ring-color auto 5px; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.6; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #7184ad;
  --bs-table-striped-bg: #fff;
  --bs-table-active-color: #7184ad;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #7184ad;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #7184ad;
  vertical-align: top;
  border-color: #eff2f7; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
    border-bottom-width: 1px; }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #dfd8fa;
  --bs-table-striped-bg: #d4cdee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c9c2e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cec8e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c9c2e1; }

.table-secondary {
  --bs-table-bg: #fcfcfd;
  --bs-table-striped-bg: #efeff0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e3e3e4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e9e9ea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e3e3e4; }

.table-success {
  --bs-table-bg: #d7f0e5;
  --bs-table-striped-bg: #cce4da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d8ce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7ded4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2d8ce; }

.table-info {
  --bs-table-bg: #ccf1f7;
  --bs-table-striped-bg: #c2e5eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d9de;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddfe4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d9de; }

.table-warning {
  --bs-table-bg: #ffeecc;
  --bs-table-striped-bg: #f2e2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d6b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdcbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d6b8; }

.table-danger {
  --bs-table-bg: #ffddd6;
  --bs-table-striped-bg: #f2d2cb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c7c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecccc6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6c7c1; }

.table-light {
  --bs-table-bg: #f6f9fc;
  --bs-table-striped-bg: #eaedef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dde0e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e6e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dde0e3; }

.table-dark {
  --bs-table-bg: #1e2e50;
  --bs-table-striped-bg: #293859;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #354362;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #2f3e5d;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #354362; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #7184ad; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7184ad;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #7184ad;
    background-color: #fff;
    border-color: #b09ef3;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #7184ad;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #7184ad;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #7184ad;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 4px; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 4px; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7184ad;
  vertical-align: middle;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  appearance: none; }
  .form-select:focus {
    border-color: #b09ef3;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #7184ad; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-check-input {
      transition: none; } }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #b09ef3;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }
  .form-check-input:checked {
    background-color: #613de6;
    border-color: #613de6; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #613de6;
    border-color: #613de6;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23b09ef3'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(97, 61, 230, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0rem rgba(97, 61, 230, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #613de6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #d0c5f8; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #613de6;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #d0c5f8; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control::placeholder {
    color: transparent; }
  .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7184ad;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 4px; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #36b37e; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(54, 179, 126, 0.9);
  border-radius: 4px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #36b37e;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2336b37e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #36b37e;
    box-shadow: 0 0 0 0rem rgba(54, 179, 126, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #36b37e;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2336b37e' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #36b37e;
    box-shadow: 0 0 0 0rem rgba(54, 179, 126, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #36b37e; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #36b37e; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(54, 179, 126, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #36b37e; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff5630; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(255, 86, 48, 0.9);
  border-radius: 4px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ff5630;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5630'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5630' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ff5630;
    box-shadow: 0 0 0 0rem rgba(255, 86, 48, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff5630;
  padding-right: calc(0.75em + 2.3125rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5630'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5630' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 1.75rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #ff5630;
    box-shadow: 0 0 0 0rem rgba(255, 86, 48, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff5630; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ff5630; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(255, 86, 48, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ff5630; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #7184ad;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #7184ad; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #613de6;
  border-color: #613de6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #5234c4;
    border-color: #4e31b8; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #5234c4;
    border-color: #4e31b8;
    box-shadow: 0 0 0 0rem rgba(121, 90, 234, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #4e31b8;
    border-color: #492ead; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(121, 90, 234, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #613de6;
    border-color: #613de6; }

.btn-secondary {
  color: #000;
  background-color: #eff2f7;
  border-color: #eff2f7; }
  .btn-secondary:hover {
    color: #000;
    background-color: #f1f4f8;
    border-color: #f1f3f8; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #000;
    background-color: #f1f4f8;
    border-color: #f1f3f8;
    box-shadow: 0 0 0 0rem rgba(203, 206, 210, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #000;
    background-color: #f2f5f9;
    border-color: #f1f3f8; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(203, 206, 210, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #000;
    background-color: #eff2f7;
    border-color: #eff2f7; }

.btn-success {
  color: #000;
  background-color: #36b37e;
  border-color: #36b37e; }
  .btn-success:hover {
    color: #000;
    background-color: #54be91;
    border-color: #4abb8b; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #000;
    background-color: #54be91;
    border-color: #4abb8b;
    box-shadow: 0 0 0 0rem rgba(46, 152, 107, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #5ec298;
    border-color: #4abb8b; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(46, 152, 107, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #000;
    background-color: #36b37e;
    border-color: #36b37e; }

.btn-info {
  color: #000;
  background-color: #00b8d9;
  border-color: #00b8d9; }
  .btn-info:hover {
    color: #000;
    background-color: #26c3df;
    border-color: #1abfdd; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #26c3df;
    border-color: #1abfdd;
    box-shadow: 0 0 0 0rem rgba(0, 156, 184, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #33c6e1;
    border-color: #1abfdd; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(0, 156, 184, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #00b8d9;
    border-color: #00b8d9; }

.btn-warning {
  color: #000;
  background-color: #ffab00;
  border-color: #ffab00; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffb826;
    border-color: #ffb31a; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffb826;
    border-color: #ffb31a;
    box-shadow: 0 0 0 0rem rgba(217, 145, 0, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffbc33;
    border-color: #ffb31a; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 145, 0, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffab00;
    border-color: #ffab00; }

.btn-danger {
  color: #000;
  background-color: #ff5630;
  border-color: #ff5630; }
  .btn-danger:hover {
    color: #000;
    background-color: #ff6f4f;
    border-color: #ff6745; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #000;
    background-color: #ff6f4f;
    border-color: #ff6745;
    box-shadow: 0 0 0 0rem rgba(217, 73, 41, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ff7859;
    border-color: #ff6745; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 73, 41, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #000;
    background-color: #ff5630;
    border-color: #ff5630; }

.btn-light {
  color: #000;
  background-color: #f6f9fc;
  border-color: #f6f9fc; }
  .btn-light:hover {
    color: #000;
    background-color: #f7fafc;
    border-color: #f7fafc; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f7fafc;
    border-color: #f7fafc;
    box-shadow: 0 0 0 0rem rgba(209, 212, 214, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f8fafd;
    border-color: #f7fafc; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(209, 212, 214, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f6f9fc;
    border-color: #f6f9fc; }

.btn-dark {
  color: #fff;
  background-color: #1e2e50;
  border-color: #1e2e50; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1a2744;
    border-color: #182540; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1a2744;
    border-color: #182540;
    box-shadow: 0 0 0 0rem rgba(64, 77, 106, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #182540;
    border-color: #17233c; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(64, 77, 106, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #1e2e50;
    border-color: #1e2e50; }

.btn-btc {
  color: #000;
  background-color: #f7931a;
  border-color: #f7931a; }
  .btn-btc:hover {
    color: #000;
    background-color: #f8a33c;
    border-color: #f89e31; }
  .btn-check:focus + .btn-btc, .btn-btc:focus {
    color: #000;
    background-color: #f8a33c;
    border-color: #f89e31;
    box-shadow: 0 0 0 0rem rgba(210, 125, 22, 0.5); }
  .btn-check:checked + .btn-btc,
  .btn-check:active + .btn-btc, .btn-btc:active, .btn-btc.active,
  .show > .btn-btc.dropdown-toggle {
    color: #000;
    background-color: #f9a948;
    border-color: #f89e31; }
    .btn-check:checked + .btn-btc:focus,
    .btn-check:active + .btn-btc:focus, .btn-btc:active:focus, .btn-btc.active:focus,
    .show > .btn-btc.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(210, 125, 22, 0.5); }
  .btn-btc:disabled, .btn-btc.disabled {
    color: #000;
    background-color: #f7931a;
    border-color: #f7931a; }

.btn-eth {
  color: #fff;
  background-color: #282828;
  border-color: #282828; }
  .btn-eth:hover {
    color: #fff;
    background-color: #222222;
    border-color: #202020; }
  .btn-check:focus + .btn-eth, .btn-eth:focus {
    color: #fff;
    background-color: #222222;
    border-color: #202020;
    box-shadow: 0 0 0 0rem rgba(72, 72, 72, 0.5); }
  .btn-check:checked + .btn-eth,
  .btn-check:active + .btn-eth, .btn-eth:active, .btn-eth.active,
  .show > .btn-eth.dropdown-toggle {
    color: #fff;
    background-color: #202020;
    border-color: #1e1e1e; }
    .btn-check:checked + .btn-eth:focus,
    .btn-check:active + .btn-eth:focus, .btn-eth:active:focus, .btn-eth.active:focus,
    .show > .btn-eth.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(72, 72, 72, 0.5); }
  .btn-eth:disabled, .btn-eth.disabled {
    color: #fff;
    background-color: #282828;
    border-color: #282828; }

.btn-usdt {
  color: #000;
  background-color: #2ca07a;
  border-color: #2ca07a; }
  .btn-usdt:hover {
    color: #000;
    background-color: #4cae8e;
    border-color: #41aa87; }
  .btn-check:focus + .btn-usdt, .btn-usdt:focus {
    color: #000;
    background-color: #4cae8e;
    border-color: #41aa87;
    box-shadow: 0 0 0 0rem rgba(37, 136, 104, 0.5); }
  .btn-check:checked + .btn-usdt,
  .btn-check:active + .btn-usdt, .btn-usdt:active, .btn-usdt.active,
  .show > .btn-usdt.dropdown-toggle {
    color: #000;
    background-color: #56b395;
    border-color: #41aa87; }
    .btn-check:checked + .btn-usdt:focus,
    .btn-check:active + .btn-usdt:focus, .btn-usdt:active:focus, .btn-usdt.active:focus,
    .show > .btn-usdt.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(37, 136, 104, 0.5); }
  .btn-usdt:disabled, .btn-usdt.disabled {
    color: #000;
    background-color: #2ca07a;
    border-color: #2ca07a; }

.btn-xrp {
  color: #fff;
  background-color: #346aa9;
  border-color: #346aa9; }
  .btn-xrp:hover {
    color: #fff;
    background-color: #2c5a90;
    border-color: #2a5587; }
  .btn-check:focus + .btn-xrp, .btn-xrp:focus {
    color: #fff;
    background-color: #2c5a90;
    border-color: #2a5587;
    box-shadow: 0 0 0 0rem rgba(82, 128, 182, 0.5); }
  .btn-check:checked + .btn-xrp,
  .btn-check:active + .btn-xrp, .btn-xrp:active, .btn-xrp.active,
  .show > .btn-xrp.dropdown-toggle {
    color: #fff;
    background-color: #2a5587;
    border-color: #27507f; }
    .btn-check:checked + .btn-xrp:focus,
    .btn-check:active + .btn-xrp:focus, .btn-xrp:active:focus, .btn-xrp.active:focus,
    .show > .btn-xrp.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(82, 128, 182, 0.5); }
  .btn-xrp:disabled, .btn-xrp.disabled {
    color: #fff;
    background-color: #346aa9;
    border-color: #346aa9; }

.btn-ltc {
  color: #000;
  background-color: #838383;
  border-color: #838383; }
  .btn-ltc:hover {
    color: #000;
    background-color: #969696;
    border-color: #8f8f8f; }
  .btn-check:focus + .btn-ltc, .btn-ltc:focus {
    color: #000;
    background-color: #969696;
    border-color: #8f8f8f;
    box-shadow: 0 0 0 0rem rgba(111, 111, 111, 0.5); }
  .btn-check:checked + .btn-ltc,
  .btn-check:active + .btn-ltc, .btn-ltc:active, .btn-ltc.active,
  .show > .btn-ltc.dropdown-toggle {
    color: #000;
    background-color: #9c9c9c;
    border-color: #8f8f8f; }
    .btn-check:checked + .btn-ltc:focus,
    .btn-check:active + .btn-ltc:focus, .btn-ltc:active:focus, .btn-ltc.active:focus,
    .show > .btn-ltc.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(111, 111, 111, 0.5); }
  .btn-ltc:disabled, .btn-ltc.disabled {
    color: #000;
    background-color: #838383;
    border-color: #838383; }

.btn-ada {
  color: #000;
  background-color: #3cc8c8;
  border-color: #3cc8c8; }
  .btn-ada:hover {
    color: #000;
    background-color: #59d0d0;
    border-color: #50cece; }
  .btn-check:focus + .btn-ada, .btn-ada:focus {
    color: #000;
    background-color: #59d0d0;
    border-color: #50cece;
    box-shadow: 0 0 0 0rem rgba(51, 170, 170, 0.5); }
  .btn-check:checked + .btn-ada,
  .btn-check:active + .btn-ada, .btn-ada:active, .btn-ada.active,
  .show > .btn-ada.dropdown-toggle {
    color: #000;
    background-color: #63d3d3;
    border-color: #50cece; }
    .btn-check:checked + .btn-ada:focus,
    .btn-check:active + .btn-ada:focus, .btn-ada:active:focus, .btn-ada.active:focus,
    .show > .btn-ada.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(51, 170, 170, 0.5); }
  .btn-ada:disabled, .btn-ada.disabled {
    color: #000;
    background-color: #3cc8c8;
    border-color: #3cc8c8; }

.btn-eos {
  color: #fff;
  background-color: #19191a;
  border-color: #19191a; }
  .btn-eos:hover {
    color: #fff;
    background-color: #151516;
    border-color: #141415; }
  .btn-check:focus + .btn-eos, .btn-eos:focus {
    color: #fff;
    background-color: #151516;
    border-color: #141415;
    box-shadow: 0 0 0 0rem rgba(60, 60, 60, 0.5); }
  .btn-check:checked + .btn-eos,
  .btn-check:active + .btn-eos, .btn-eos:active, .btn-eos.active,
  .show > .btn-eos.dropdown-toggle {
    color: #fff;
    background-color: #141415;
    border-color: #131314; }
    .btn-check:checked + .btn-eos:focus,
    .btn-check:active + .btn-eos:focus, .btn-eos:active:focus, .btn-eos.active:focus,
    .show > .btn-eos.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(60, 60, 60, 0.5); }
  .btn-eos:disabled, .btn-eos.disabled {
    color: #fff;
    background-color: #19191a;
    border-color: #19191a; }

.btn-xmr {
  color: #000;
  background-color: #ff6600;
  border-color: #ff6600; }
  .btn-xmr:hover {
    color: #000;
    background-color: #ff7d26;
    border-color: #ff751a; }
  .btn-check:focus + .btn-xmr, .btn-xmr:focus {
    color: #000;
    background-color: #ff7d26;
    border-color: #ff751a;
    box-shadow: 0 0 0 0rem rgba(217, 87, 0, 0.5); }
  .btn-check:checked + .btn-xmr,
  .btn-check:active + .btn-xmr, .btn-xmr:active, .btn-xmr.active,
  .show > .btn-xmr.dropdown-toggle {
    color: #000;
    background-color: #ff8533;
    border-color: #ff751a; }
    .btn-check:checked + .btn-xmr:focus,
    .btn-check:active + .btn-xmr:focus, .btn-xmr:active:focus, .btn-xmr.active:focus,
    .show > .btn-xmr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(217, 87, 0, 0.5); }
  .btn-xmr:disabled, .btn-xmr.disabled {
    color: #000;
    background-color: #ff6600;
    border-color: #ff6600; }

.btn-xtz {
  color: #000;
  background-color: #a6df00;
  border-color: #a6df00; }
  .btn-xtz:hover {
    color: #000;
    background-color: #b3e426;
    border-color: #afe21a; }
  .btn-check:focus + .btn-xtz, .btn-xtz:focus {
    color: #000;
    background-color: #b3e426;
    border-color: #afe21a;
    box-shadow: 0 0 0 0rem rgba(141, 190, 0, 0.5); }
  .btn-check:checked + .btn-xtz,
  .btn-check:active + .btn-xtz, .btn-xtz:active, .btn-xtz.active,
  .show > .btn-xtz.dropdown-toggle {
    color: #000;
    background-color: #b8e533;
    border-color: #afe21a; }
    .btn-check:checked + .btn-xtz:focus,
    .btn-check:active + .btn-xtz:focus, .btn-xtz:active:focus, .btn-xtz.active:focus,
    .show > .btn-xtz.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(141, 190, 0, 0.5); }
  .btn-xtz:disabled, .btn-xtz.disabled {
    color: #000;
    background-color: #a6df00;
    border-color: #a6df00; }

.btn-xem {
  color: #000;
  background-color: #41bf76;
  border-color: #41bf76; }
  .btn-xem:hover {
    color: #000;
    background-color: #5ec98b;
    border-color: #54c584; }
  .btn-check:focus + .btn-xem, .btn-xem:focus {
    color: #000;
    background-color: #5ec98b;
    border-color: #54c584;
    box-shadow: 0 0 0 0rem rgba(55, 162, 100, 0.5); }
  .btn-check:checked + .btn-xem,
  .btn-check:active + .btn-xem, .btn-xem:active, .btn-xem.active,
  .show > .btn-xem.dropdown-toggle {
    color: #000;
    background-color: #67cc91;
    border-color: #54c584; }
    .btn-check:checked + .btn-xem:focus,
    .btn-check:active + .btn-xem:focus, .btn-xem:active:focus, .btn-xem.active:focus,
    .show > .btn-xem.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(55, 162, 100, 0.5); }
  .btn-xem:disabled, .btn-xem.disabled {
    color: #000;
    background-color: #41bf76;
    border-color: #41bf76; }

.btn-neo {
  color: #000;
  background-color: #58bf00;
  border-color: #58bf00; }
  .btn-neo:hover {
    color: #000;
    background-color: #71c926;
    border-color: #69c51a; }
  .btn-check:focus + .btn-neo, .btn-neo:focus {
    color: #000;
    background-color: #71c926;
    border-color: #69c51a;
    box-shadow: 0 0 0 0rem rgba(75, 162, 0, 0.5); }
  .btn-check:checked + .btn-neo,
  .btn-check:active + .btn-neo, .btn-neo:active, .btn-neo.active,
  .show > .btn-neo.dropdown-toggle {
    color: #000;
    background-color: #79cc33;
    border-color: #69c51a; }
    .btn-check:checked + .btn-neo:focus,
    .btn-check:active + .btn-neo:focus, .btn-neo:active:focus, .btn-neo.active:focus,
    .show > .btn-neo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(75, 162, 0, 0.5); }
  .btn-neo:disabled, .btn-neo.disabled {
    color: #000;
    background-color: #58bf00;
    border-color: #58bf00; }

.btn-dash {
  color: #fff;
  background-color: #1c75bc;
  border-color: #1c75bc; }
  .btn-dash:hover {
    color: #fff;
    background-color: #1863a0;
    border-color: #165e96; }
  .btn-check:focus + .btn-dash, .btn-dash:focus {
    color: #fff;
    background-color: #1863a0;
    border-color: #165e96;
    box-shadow: 0 0 0 0rem rgba(62, 138, 198, 0.5); }
  .btn-check:checked + .btn-dash,
  .btn-check:active + .btn-dash, .btn-dash:active, .btn-dash.active,
  .show > .btn-dash.dropdown-toggle {
    color: #fff;
    background-color: #165e96;
    border-color: #15588d; }
    .btn-check:checked + .btn-dash:focus,
    .btn-check:active + .btn-dash:focus, .btn-dash:active:focus, .btn-dash.active:focus,
    .show > .btn-dash.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(62, 138, 198, 0.5); }
  .btn-dash:disabled, .btn-dash.disabled {
    color: #fff;
    background-color: #1c75bc;
    border-color: #1c75bc; }

.btn-etc {
  color: #000;
  background-color: #669073;
  border-color: #669073; }
  .btn-etc:hover {
    color: #000;
    background-color: #7da188;
    border-color: #759b81; }
  .btn-check:focus + .btn-etc, .btn-etc:focus {
    color: #000;
    background-color: #7da188;
    border-color: #759b81;
    box-shadow: 0 0 0 0rem rgba(87, 122, 98, 0.5); }
  .btn-check:checked + .btn-etc,
  .btn-check:active + .btn-etc, .btn-etc:active, .btn-etc.active,
  .show > .btn-etc.dropdown-toggle {
    color: #000;
    background-color: #85a68f;
    border-color: #759b81; }
    .btn-check:checked + .btn-etc:focus,
    .btn-check:active + .btn-etc:focus, .btn-etc:active:focus, .btn-etc.active:focus,
    .show > .btn-etc.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(87, 122, 98, 0.5); }
  .btn-etc:disabled, .btn-etc.disabled {
    color: #000;
    background-color: #669073;
    border-color: #669073; }

.btn-dgb {
  color: #fff;
  background-color: #0066cc;
  border-color: #0066cc; }
  .btn-dgb:hover {
    color: #fff;
    background-color: #0057ad;
    border-color: #0052a3; }
  .btn-check:focus + .btn-dgb, .btn-dgb:focus {
    color: #fff;
    background-color: #0057ad;
    border-color: #0052a3;
    box-shadow: 0 0 0 0rem rgba(38, 125, 212, 0.5); }
  .btn-check:checked + .btn-dgb,
  .btn-check:active + .btn-dgb, .btn-dgb:active, .btn-dgb.active,
  .show > .btn-dgb.dropdown-toggle {
    color: #fff;
    background-color: #0052a3;
    border-color: #004d99; }
    .btn-check:checked + .btn-dgb:focus,
    .btn-check:active + .btn-dgb:focus, .btn-dgb:active:focus, .btn-dgb.active:focus,
    .show > .btn-dgb.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(38, 125, 212, 0.5); }
  .btn-dgb:disabled, .btn-dgb.disabled {
    color: #fff;
    background-color: #0066cc;
    border-color: #0066cc; }

.btn-dcr {
  color: #000;
  background-color: #3b7cfb;
  border-color: #3b7cfb; }
  .btn-dcr:hover {
    color: #000;
    background-color: #5890fc;
    border-color: #4f89fb; }
  .btn-check:focus + .btn-dcr, .btn-dcr:focus {
    color: #000;
    background-color: #5890fc;
    border-color: #4f89fb;
    box-shadow: 0 0 0 0rem rgba(50, 105, 213, 0.5); }
  .btn-check:checked + .btn-dcr,
  .btn-check:active + .btn-dcr, .btn-dcr:active, .btn-dcr.active,
  .show > .btn-dcr.dropdown-toggle {
    color: #000;
    background-color: #6296fc;
    border-color: #4f89fb; }
    .btn-check:checked + .btn-dcr:focus,
    .btn-check:active + .btn-dcr:focus, .btn-dcr:active:focus, .btn-dcr.active:focus,
    .show > .btn-dcr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(50, 105, 213, 0.5); }
  .btn-dcr:disabled, .btn-dcr.disabled {
    color: #000;
    background-color: #3b7cfb;
    border-color: #3b7cfb; }

.btn-rep {
  color: #000;
  background-color: #40a2cb;
  border-color: #40a2cb; }
  .btn-rep:hover {
    color: #000;
    background-color: #5db0d3;
    border-color: #53abd0; }
  .btn-check:focus + .btn-rep, .btn-rep:focus {
    color: #000;
    background-color: #5db0d3;
    border-color: #53abd0;
    box-shadow: 0 0 0 0rem rgba(54, 138, 173, 0.5); }
  .btn-check:checked + .btn-rep,
  .btn-check:active + .btn-rep, .btn-rep:active, .btn-rep.active,
  .show > .btn-rep.dropdown-toggle {
    color: #000;
    background-color: #66b5d5;
    border-color: #53abd0; }
    .btn-check:checked + .btn-rep:focus,
    .btn-check:active + .btn-rep:focus, .btn-rep:active:focus, .btn-rep.active:focus,
    .show > .btn-rep.dropdown-toggle:focus {
      box-shadow: 0 0 0 0rem rgba(54, 138, 173, 0.5); }
  .btn-rep:disabled, .btn-rep.disabled {
    color: #000;
    background-color: #40a2cb;
    border-color: #40a2cb; }

.btn-outline-primary {
  color: #613de6;
  border-color: #613de6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #613de6;
    border-color: #613de6; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #613de6;
    border-color: #613de6; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #613de6;
    background-color: transparent; }

.btn-outline-secondary {
  color: #eff2f7;
  border-color: #eff2f7; }
  .btn-outline-secondary:hover {
    color: #000;
    background-color: #eff2f7;
    border-color: #eff2f7; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0rem rgba(239, 242, 247, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #000;
    background-color: #eff2f7;
    border-color: #eff2f7; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(239, 242, 247, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #eff2f7;
    background-color: transparent; }

.btn-outline-success {
  color: #36b37e;
  border-color: #36b37e; }
  .btn-outline-success:hover {
    color: #000;
    background-color: #36b37e;
    border-color: #36b37e; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0rem rgba(54, 179, 126, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #36b37e;
    border-color: #36b37e; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(54, 179, 126, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #36b37e;
    background-color: transparent; }

.btn-outline-info {
  color: #00b8d9;
  border-color: #00b8d9; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #00b8d9;
    border-color: #00b8d9; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0rem rgba(0, 184, 217, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #00b8d9;
    border-color: #00b8d9; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(0, 184, 217, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #00b8d9;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffab00;
  border-color: #ffab00; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffab00;
    border-color: #ffab00; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0rem rgba(255, 171, 0, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffab00;
    border-color: #ffab00; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 171, 0, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffab00;
    background-color: transparent; }

.btn-outline-danger {
  color: #ff5630;
  border-color: #ff5630; }
  .btn-outline-danger:hover {
    color: #000;
    background-color: #ff5630;
    border-color: #ff5630; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0rem rgba(255, 86, 48, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #ff5630;
    border-color: #ff5630; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 86, 48, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #ff5630;
    background-color: transparent; }

.btn-outline-light {
  color: #f6f9fc;
  border-color: #f6f9fc; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f6f9fc;
    border-color: #f6f9fc; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0rem rgba(246, 249, 252, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f6f9fc;
    border-color: #f6f9fc; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(246, 249, 252, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f6f9fc;
    background-color: transparent; }

.btn-outline-dark {
  color: #1e2e50;
  border-color: #1e2e50; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #1e2e50;
    border-color: #1e2e50; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0rem rgba(30, 46, 80, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #1e2e50;
    border-color: #1e2e50; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(30, 46, 80, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #1e2e50;
    background-color: transparent; }

.btn-outline-btc {
  color: #f7931a;
  border-color: #f7931a; }
  .btn-outline-btc:hover {
    color: #000;
    background-color: #f7931a;
    border-color: #f7931a; }
  .btn-check:focus + .btn-outline-btc, .btn-outline-btc:focus {
    box-shadow: 0 0 0 0rem rgba(247, 147, 26, 0.5); }
  .btn-check:checked + .btn-outline-btc,
  .btn-check:active + .btn-outline-btc, .btn-outline-btc:active, .btn-outline-btc.active, .btn-outline-btc.dropdown-toggle.show {
    color: #000;
    background-color: #f7931a;
    border-color: #f7931a; }
    .btn-check:checked + .btn-outline-btc:focus,
    .btn-check:active + .btn-outline-btc:focus, .btn-outline-btc:active:focus, .btn-outline-btc.active:focus, .btn-outline-btc.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(247, 147, 26, 0.5); }
  .btn-outline-btc:disabled, .btn-outline-btc.disabled {
    color: #f7931a;
    background-color: transparent; }

.btn-outline-eth {
  color: #282828;
  border-color: #282828; }
  .btn-outline-eth:hover {
    color: #fff;
    background-color: #282828;
    border-color: #282828; }
  .btn-check:focus + .btn-outline-eth, .btn-outline-eth:focus {
    box-shadow: 0 0 0 0rem rgba(40, 40, 40, 0.5); }
  .btn-check:checked + .btn-outline-eth,
  .btn-check:active + .btn-outline-eth, .btn-outline-eth:active, .btn-outline-eth.active, .btn-outline-eth.dropdown-toggle.show {
    color: #fff;
    background-color: #282828;
    border-color: #282828; }
    .btn-check:checked + .btn-outline-eth:focus,
    .btn-check:active + .btn-outline-eth:focus, .btn-outline-eth:active:focus, .btn-outline-eth.active:focus, .btn-outline-eth.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(40, 40, 40, 0.5); }
  .btn-outline-eth:disabled, .btn-outline-eth.disabled {
    color: #282828;
    background-color: transparent; }

.btn-outline-usdt {
  color: #2ca07a;
  border-color: #2ca07a; }
  .btn-outline-usdt:hover {
    color: #000;
    background-color: #2ca07a;
    border-color: #2ca07a; }
  .btn-check:focus + .btn-outline-usdt, .btn-outline-usdt:focus {
    box-shadow: 0 0 0 0rem rgba(44, 160, 122, 0.5); }
  .btn-check:checked + .btn-outline-usdt,
  .btn-check:active + .btn-outline-usdt, .btn-outline-usdt:active, .btn-outline-usdt.active, .btn-outline-usdt.dropdown-toggle.show {
    color: #000;
    background-color: #2ca07a;
    border-color: #2ca07a; }
    .btn-check:checked + .btn-outline-usdt:focus,
    .btn-check:active + .btn-outline-usdt:focus, .btn-outline-usdt:active:focus, .btn-outline-usdt.active:focus, .btn-outline-usdt.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(44, 160, 122, 0.5); }
  .btn-outline-usdt:disabled, .btn-outline-usdt.disabled {
    color: #2ca07a;
    background-color: transparent; }

.btn-outline-xrp {
  color: #346aa9;
  border-color: #346aa9; }
  .btn-outline-xrp:hover {
    color: #fff;
    background-color: #346aa9;
    border-color: #346aa9; }
  .btn-check:focus + .btn-outline-xrp, .btn-outline-xrp:focus {
    box-shadow: 0 0 0 0rem rgba(52, 106, 169, 0.5); }
  .btn-check:checked + .btn-outline-xrp,
  .btn-check:active + .btn-outline-xrp, .btn-outline-xrp:active, .btn-outline-xrp.active, .btn-outline-xrp.dropdown-toggle.show {
    color: #fff;
    background-color: #346aa9;
    border-color: #346aa9; }
    .btn-check:checked + .btn-outline-xrp:focus,
    .btn-check:active + .btn-outline-xrp:focus, .btn-outline-xrp:active:focus, .btn-outline-xrp.active:focus, .btn-outline-xrp.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(52, 106, 169, 0.5); }
  .btn-outline-xrp:disabled, .btn-outline-xrp.disabled {
    color: #346aa9;
    background-color: transparent; }

.btn-outline-ltc {
  color: #838383;
  border-color: #838383; }
  .btn-outline-ltc:hover {
    color: #000;
    background-color: #838383;
    border-color: #838383; }
  .btn-check:focus + .btn-outline-ltc, .btn-outline-ltc:focus {
    box-shadow: 0 0 0 0rem rgba(131, 131, 131, 0.5); }
  .btn-check:checked + .btn-outline-ltc,
  .btn-check:active + .btn-outline-ltc, .btn-outline-ltc:active, .btn-outline-ltc.active, .btn-outline-ltc.dropdown-toggle.show {
    color: #000;
    background-color: #838383;
    border-color: #838383; }
    .btn-check:checked + .btn-outline-ltc:focus,
    .btn-check:active + .btn-outline-ltc:focus, .btn-outline-ltc:active:focus, .btn-outline-ltc.active:focus, .btn-outline-ltc.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(131, 131, 131, 0.5); }
  .btn-outline-ltc:disabled, .btn-outline-ltc.disabled {
    color: #838383;
    background-color: transparent; }

.btn-outline-ada {
  color: #3cc8c8;
  border-color: #3cc8c8; }
  .btn-outline-ada:hover {
    color: #000;
    background-color: #3cc8c8;
    border-color: #3cc8c8; }
  .btn-check:focus + .btn-outline-ada, .btn-outline-ada:focus {
    box-shadow: 0 0 0 0rem rgba(60, 200, 200, 0.5); }
  .btn-check:checked + .btn-outline-ada,
  .btn-check:active + .btn-outline-ada, .btn-outline-ada:active, .btn-outline-ada.active, .btn-outline-ada.dropdown-toggle.show {
    color: #000;
    background-color: #3cc8c8;
    border-color: #3cc8c8; }
    .btn-check:checked + .btn-outline-ada:focus,
    .btn-check:active + .btn-outline-ada:focus, .btn-outline-ada:active:focus, .btn-outline-ada.active:focus, .btn-outline-ada.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(60, 200, 200, 0.5); }
  .btn-outline-ada:disabled, .btn-outline-ada.disabled {
    color: #3cc8c8;
    background-color: transparent; }

.btn-outline-eos {
  color: #19191a;
  border-color: #19191a; }
  .btn-outline-eos:hover {
    color: #fff;
    background-color: #19191a;
    border-color: #19191a; }
  .btn-check:focus + .btn-outline-eos, .btn-outline-eos:focus {
    box-shadow: 0 0 0 0rem rgba(25, 25, 26, 0.5); }
  .btn-check:checked + .btn-outline-eos,
  .btn-check:active + .btn-outline-eos, .btn-outline-eos:active, .btn-outline-eos.active, .btn-outline-eos.dropdown-toggle.show {
    color: #fff;
    background-color: #19191a;
    border-color: #19191a; }
    .btn-check:checked + .btn-outline-eos:focus,
    .btn-check:active + .btn-outline-eos:focus, .btn-outline-eos:active:focus, .btn-outline-eos.active:focus, .btn-outline-eos.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(25, 25, 26, 0.5); }
  .btn-outline-eos:disabled, .btn-outline-eos.disabled {
    color: #19191a;
    background-color: transparent; }

.btn-outline-xmr {
  color: #ff6600;
  border-color: #ff6600; }
  .btn-outline-xmr:hover {
    color: #000;
    background-color: #ff6600;
    border-color: #ff6600; }
  .btn-check:focus + .btn-outline-xmr, .btn-outline-xmr:focus {
    box-shadow: 0 0 0 0rem rgba(255, 102, 0, 0.5); }
  .btn-check:checked + .btn-outline-xmr,
  .btn-check:active + .btn-outline-xmr, .btn-outline-xmr:active, .btn-outline-xmr.active, .btn-outline-xmr.dropdown-toggle.show {
    color: #000;
    background-color: #ff6600;
    border-color: #ff6600; }
    .btn-check:checked + .btn-outline-xmr:focus,
    .btn-check:active + .btn-outline-xmr:focus, .btn-outline-xmr:active:focus, .btn-outline-xmr.active:focus, .btn-outline-xmr.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(255, 102, 0, 0.5); }
  .btn-outline-xmr:disabled, .btn-outline-xmr.disabled {
    color: #ff6600;
    background-color: transparent; }

.btn-outline-xtz {
  color: #a6df00;
  border-color: #a6df00; }
  .btn-outline-xtz:hover {
    color: #000;
    background-color: #a6df00;
    border-color: #a6df00; }
  .btn-check:focus + .btn-outline-xtz, .btn-outline-xtz:focus {
    box-shadow: 0 0 0 0rem rgba(166, 223, 0, 0.5); }
  .btn-check:checked + .btn-outline-xtz,
  .btn-check:active + .btn-outline-xtz, .btn-outline-xtz:active, .btn-outline-xtz.active, .btn-outline-xtz.dropdown-toggle.show {
    color: #000;
    background-color: #a6df00;
    border-color: #a6df00; }
    .btn-check:checked + .btn-outline-xtz:focus,
    .btn-check:active + .btn-outline-xtz:focus, .btn-outline-xtz:active:focus, .btn-outline-xtz.active:focus, .btn-outline-xtz.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(166, 223, 0, 0.5); }
  .btn-outline-xtz:disabled, .btn-outline-xtz.disabled {
    color: #a6df00;
    background-color: transparent; }

.btn-outline-xem {
  color: #41bf76;
  border-color: #41bf76; }
  .btn-outline-xem:hover {
    color: #000;
    background-color: #41bf76;
    border-color: #41bf76; }
  .btn-check:focus + .btn-outline-xem, .btn-outline-xem:focus {
    box-shadow: 0 0 0 0rem rgba(65, 191, 118, 0.5); }
  .btn-check:checked + .btn-outline-xem,
  .btn-check:active + .btn-outline-xem, .btn-outline-xem:active, .btn-outline-xem.active, .btn-outline-xem.dropdown-toggle.show {
    color: #000;
    background-color: #41bf76;
    border-color: #41bf76; }
    .btn-check:checked + .btn-outline-xem:focus,
    .btn-check:active + .btn-outline-xem:focus, .btn-outline-xem:active:focus, .btn-outline-xem.active:focus, .btn-outline-xem.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(65, 191, 118, 0.5); }
  .btn-outline-xem:disabled, .btn-outline-xem.disabled {
    color: #41bf76;
    background-color: transparent; }

.btn-outline-neo {
  color: #58bf00;
  border-color: #58bf00; }
  .btn-outline-neo:hover {
    color: #000;
    background-color: #58bf00;
    border-color: #58bf00; }
  .btn-check:focus + .btn-outline-neo, .btn-outline-neo:focus {
    box-shadow: 0 0 0 0rem rgba(88, 191, 0, 0.5); }
  .btn-check:checked + .btn-outline-neo,
  .btn-check:active + .btn-outline-neo, .btn-outline-neo:active, .btn-outline-neo.active, .btn-outline-neo.dropdown-toggle.show {
    color: #000;
    background-color: #58bf00;
    border-color: #58bf00; }
    .btn-check:checked + .btn-outline-neo:focus,
    .btn-check:active + .btn-outline-neo:focus, .btn-outline-neo:active:focus, .btn-outline-neo.active:focus, .btn-outline-neo.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(88, 191, 0, 0.5); }
  .btn-outline-neo:disabled, .btn-outline-neo.disabled {
    color: #58bf00;
    background-color: transparent; }

.btn-outline-dash {
  color: #1c75bc;
  border-color: #1c75bc; }
  .btn-outline-dash:hover {
    color: #fff;
    background-color: #1c75bc;
    border-color: #1c75bc; }
  .btn-check:focus + .btn-outline-dash, .btn-outline-dash:focus {
    box-shadow: 0 0 0 0rem rgba(28, 117, 188, 0.5); }
  .btn-check:checked + .btn-outline-dash,
  .btn-check:active + .btn-outline-dash, .btn-outline-dash:active, .btn-outline-dash.active, .btn-outline-dash.dropdown-toggle.show {
    color: #fff;
    background-color: #1c75bc;
    border-color: #1c75bc; }
    .btn-check:checked + .btn-outline-dash:focus,
    .btn-check:active + .btn-outline-dash:focus, .btn-outline-dash:active:focus, .btn-outline-dash.active:focus, .btn-outline-dash.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(28, 117, 188, 0.5); }
  .btn-outline-dash:disabled, .btn-outline-dash.disabled {
    color: #1c75bc;
    background-color: transparent; }

.btn-outline-etc {
  color: #669073;
  border-color: #669073; }
  .btn-outline-etc:hover {
    color: #000;
    background-color: #669073;
    border-color: #669073; }
  .btn-check:focus + .btn-outline-etc, .btn-outline-etc:focus {
    box-shadow: 0 0 0 0rem rgba(102, 144, 115, 0.5); }
  .btn-check:checked + .btn-outline-etc,
  .btn-check:active + .btn-outline-etc, .btn-outline-etc:active, .btn-outline-etc.active, .btn-outline-etc.dropdown-toggle.show {
    color: #000;
    background-color: #669073;
    border-color: #669073; }
    .btn-check:checked + .btn-outline-etc:focus,
    .btn-check:active + .btn-outline-etc:focus, .btn-outline-etc:active:focus, .btn-outline-etc.active:focus, .btn-outline-etc.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(102, 144, 115, 0.5); }
  .btn-outline-etc:disabled, .btn-outline-etc.disabled {
    color: #669073;
    background-color: transparent; }

.btn-outline-dgb {
  color: #0066cc;
  border-color: #0066cc; }
  .btn-outline-dgb:hover {
    color: #fff;
    background-color: #0066cc;
    border-color: #0066cc; }
  .btn-check:focus + .btn-outline-dgb, .btn-outline-dgb:focus {
    box-shadow: 0 0 0 0rem rgba(0, 102, 204, 0.5); }
  .btn-check:checked + .btn-outline-dgb,
  .btn-check:active + .btn-outline-dgb, .btn-outline-dgb:active, .btn-outline-dgb.active, .btn-outline-dgb.dropdown-toggle.show {
    color: #fff;
    background-color: #0066cc;
    border-color: #0066cc; }
    .btn-check:checked + .btn-outline-dgb:focus,
    .btn-check:active + .btn-outline-dgb:focus, .btn-outline-dgb:active:focus, .btn-outline-dgb.active:focus, .btn-outline-dgb.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(0, 102, 204, 0.5); }
  .btn-outline-dgb:disabled, .btn-outline-dgb.disabled {
    color: #0066cc;
    background-color: transparent; }

.btn-outline-dcr {
  color: #3b7cfb;
  border-color: #3b7cfb; }
  .btn-outline-dcr:hover {
    color: #000;
    background-color: #3b7cfb;
    border-color: #3b7cfb; }
  .btn-check:focus + .btn-outline-dcr, .btn-outline-dcr:focus {
    box-shadow: 0 0 0 0rem rgba(59, 124, 251, 0.5); }
  .btn-check:checked + .btn-outline-dcr,
  .btn-check:active + .btn-outline-dcr, .btn-outline-dcr:active, .btn-outline-dcr.active, .btn-outline-dcr.dropdown-toggle.show {
    color: #000;
    background-color: #3b7cfb;
    border-color: #3b7cfb; }
    .btn-check:checked + .btn-outline-dcr:focus,
    .btn-check:active + .btn-outline-dcr:focus, .btn-outline-dcr:active:focus, .btn-outline-dcr.active:focus, .btn-outline-dcr.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(59, 124, 251, 0.5); }
  .btn-outline-dcr:disabled, .btn-outline-dcr.disabled {
    color: #3b7cfb;
    background-color: transparent; }

.btn-outline-rep {
  color: #40a2cb;
  border-color: #40a2cb; }
  .btn-outline-rep:hover {
    color: #000;
    background-color: #40a2cb;
    border-color: #40a2cb; }
  .btn-check:focus + .btn-outline-rep, .btn-outline-rep:focus {
    box-shadow: 0 0 0 0rem rgba(64, 162, 203, 0.5); }
  .btn-check:checked + .btn-outline-rep,
  .btn-check:active + .btn-outline-rep, .btn-outline-rep:active, .btn-outline-rep.active, .btn-outline-rep.dropdown-toggle.show {
    color: #000;
    background-color: #40a2cb;
    border-color: #40a2cb; }
    .btn-check:checked + .btn-outline-rep:focus,
    .btn-check:active + .btn-outline-rep:focus, .btn-outline-rep:active:focus, .btn-outline-rep.active:focus, .btn-outline-rep.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0rem rgba(64, 162, 203, 0.5); }
  .btn-outline-rep:disabled, .btn-outline-rep.disabled {
    color: #40a2cb;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #613de6;
  text-decoration: underline; }
  .btn-link:hover {
    color: #4e31b8; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #7184ad;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .dropdown-menu[style] {
    right: auto !important; }

.dropdown-menu-start {
  --bs-position: start;
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */; }

.dropdown-menu-end {
  --bs-position: end;
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  .dropdown-menu-sm-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  .dropdown-menu-md-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  .dropdown-menu-lg-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  .dropdown-menu-xl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */; }
  .dropdown-menu-xxl-end {
    --bs-position: end;
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #613de6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #613de6; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 4px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #613de6; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  align-items: center;
  width: 100%; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-collapse {
    display: flex !important; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1rem /* rtl:ignore */; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 3px 3px 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 3px 3px; }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 3px; }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #7184ad;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button.collapsed {
    border-bottom-width: 0; }
  .accordion-button:not(.collapsed) {
    color: #5737cf;
    background-color: #efecfd; }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235737cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237184ad'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #b09ef3;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #613de6;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #4e31b8;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #4e31b8;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #613de6;
  border-color: #613de6; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 4px; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 4px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #3a258a;
  background-color: #dfd8fa;
  border-color: #d0c5f8; }
  .alert-primary .alert-link {
    color: #2e1e6e; }

.alert-secondary {
  color: #606163;
  background-color: #fcfcfd;
  border-color: #fafbfd; }
  .alert-secondary .alert-link {
    color: #4d4e4f; }

.alert-success {
  color: #206b4c;
  background-color: #d7f0e5;
  border-color: #c3e8d8; }
  .alert-success .alert-link {
    color: #1a563d; }

.alert-info {
  color: #006e82;
  background-color: #ccf1f7;
  border-color: #b3eaf4; }
  .alert-info .alert-link {
    color: #005868; }

.alert-warning {
  color: #664400;
  background-color: #ffeecc;
  border-color: #ffe6b3; }
  .alert-warning .alert-link {
    color: #523600; }

.alert-danger {
  color: #99341d;
  background-color: #ffddd6;
  border-color: #ffccc1; }
  .alert-danger .alert-link {
    color: #7a2a17; }

.alert-light {
  color: #626465;
  background-color: #fdfefe;
  border-color: #fcfdfe; }
  .alert-light .alert-link {
    color: #4e5051; }

.alert-dark {
  color: #121c30;
  background-color: #d2d5dc;
  border-color: #bcc0cb; }
  .alert-dark .alert-link {
    color: #0e1626; }

.alert-btc {
  color: #945810;
  background-color: #fde9d1;
  border-color: #fddfba; }
  .alert-btc .alert-link {
    color: #76460d; }

.alert-eth {
  color: #181818;
  background-color: #d4d4d4;
  border-color: #bfbfbf; }
  .alert-eth .alert-link {
    color: #131313; }

.alert-usdt {
  color: #1a6049;
  background-color: #d5ece4;
  border-color: #c0e3d7; }
  .alert-usdt .alert-link {
    color: #154d3a; }

.alert-xrp {
  color: #1f4065;
  background-color: #d6e1ee;
  border-color: #c2d2e5; }
  .alert-xrp .alert-link {
    color: #193351; }

.alert-ltc {
  color: #4f4f4f;
  background-color: #e6e6e6;
  border-color: #dadada; }
  .alert-ltc .alert-link {
    color: #3f3f3f; }

.alert-ada {
  color: #247878;
  background-color: #d8f4f4;
  border-color: #c5efef; }
  .alert-ada .alert-link {
    color: #1d6060; }

.alert-eos {
  color: #0f0f10;
  background-color: #d1d1d1;
  border-color: #bababa; }
  .alert-eos .alert-link {
    color: #0c0c0d; }

.alert-xmr {
  color: #993d00;
  background-color: #ffe0cc;
  border-color: #ffd1b3; }
  .alert-xmr .alert-link {
    color: #7a3100; }

.alert-xtz {
  color: #425900;
  background-color: #edf9cc;
  border-color: #e4f5b3; }
  .alert-xtz .alert-link {
    color: #354700; }

.alert-xem {
  color: #277347;
  background-color: #d9f2e4;
  border-color: #c6ecd6; }
  .alert-xem .alert-link {
    color: #1f5c39; }

.alert-neo {
  color: #357300;
  background-color: #def2cc;
  border-color: #cdecb3; }
  .alert-neo .alert-link {
    color: #2a5c00; }

.alert-dash {
  color: #114671;
  background-color: #d2e3f2;
  border-color: #bbd6eb; }
  .alert-dash .alert-link {
    color: #0e385a; }

.alert-etc {
  color: #3d5645;
  background-color: #e0e9e3;
  border-color: #d1ded5; }
  .alert-etc .alert-link {
    color: #314537; }

.alert-dgb {
  color: #003d7a;
  background-color: #cce0f5;
  border-color: #b3d1f0; }
  .alert-dgb .alert-link {
    color: #003162; }

.alert-dcr {
  color: #234a97;
  background-color: #d8e5fe;
  border-color: #c4d8fe; }
  .alert-dcr .alert-link {
    color: #1c3b79; }

.alert-rep {
  color: #26617a;
  background-color: #d9ecf5;
  border-color: #c6e3ef; }
  .alert-rep .alert-link {
    color: #1e4e62; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 4px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #613de6;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #7184ad;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #613de6;
    border-color: #613de6; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #3a258a;
  background-color: #dfd8fa; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #3a258a;
    background-color: #c9c2e1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #3a258a;
    border-color: #3a258a; }

.list-group-item-secondary {
  color: #606163;
  background-color: #fcfcfd; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #606163;
    background-color: #e3e3e4; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #606163;
    border-color: #606163; }

.list-group-item-success {
  color: #206b4c;
  background-color: #d7f0e5; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #206b4c;
    background-color: #c2d8ce; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #206b4c;
    border-color: #206b4c; }

.list-group-item-info {
  color: #006e82;
  background-color: #ccf1f7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #006e82;
    background-color: #b8d9de; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #006e82;
    border-color: #006e82; }

.list-group-item-warning {
  color: #664400;
  background-color: #ffeecc; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664400;
    background-color: #e6d6b8; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664400;
    border-color: #664400; }

.list-group-item-danger {
  color: #99341d;
  background-color: #ffddd6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #99341d;
    background-color: #e6c7c1; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #99341d;
    border-color: #99341d; }

.list-group-item-light {
  color: #626465;
  background-color: #fdfefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #626465;
    background-color: #e4e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #626465;
    border-color: #626465; }

.list-group-item-dark {
  color: #121c30;
  background-color: #d2d5dc; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #121c30;
    background-color: #bdc0c6; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #121c30;
    border-color: #121c30; }

.list-group-item-btc {
  color: #945810;
  background-color: #fde9d1; }
  .list-group-item-btc.list-group-item-action:hover, .list-group-item-btc.list-group-item-action:focus {
    color: #945810;
    background-color: #e4d2bc; }
  .list-group-item-btc.list-group-item-action.active {
    color: #fff;
    background-color: #945810;
    border-color: #945810; }

.list-group-item-eth {
  color: #181818;
  background-color: #d4d4d4; }
  .list-group-item-eth.list-group-item-action:hover, .list-group-item-eth.list-group-item-action:focus {
    color: #181818;
    background-color: #bfbfbf; }
  .list-group-item-eth.list-group-item-action.active {
    color: #fff;
    background-color: #181818;
    border-color: #181818; }

.list-group-item-usdt {
  color: #1a6049;
  background-color: #d5ece4; }
  .list-group-item-usdt.list-group-item-action:hover, .list-group-item-usdt.list-group-item-action:focus {
    color: #1a6049;
    background-color: #c0d4cd; }
  .list-group-item-usdt.list-group-item-action.active {
    color: #fff;
    background-color: #1a6049;
    border-color: #1a6049; }

.list-group-item-xrp {
  color: #1f4065;
  background-color: #d6e1ee; }
  .list-group-item-xrp.list-group-item-action:hover, .list-group-item-xrp.list-group-item-action:focus {
    color: #1f4065;
    background-color: #c1cbd6; }
  .list-group-item-xrp.list-group-item-action.active {
    color: #fff;
    background-color: #1f4065;
    border-color: #1f4065; }

.list-group-item-ltc {
  color: #4f4f4f;
  background-color: #e6e6e6; }
  .list-group-item-ltc.list-group-item-action:hover, .list-group-item-ltc.list-group-item-action:focus {
    color: #4f4f4f;
    background-color: #cfcfcf; }
  .list-group-item-ltc.list-group-item-action.active {
    color: #fff;
    background-color: #4f4f4f;
    border-color: #4f4f4f; }

.list-group-item-ada {
  color: #247878;
  background-color: #d8f4f4; }
  .list-group-item-ada.list-group-item-action:hover, .list-group-item-ada.list-group-item-action:focus {
    color: #247878;
    background-color: #c2dcdc; }
  .list-group-item-ada.list-group-item-action.active {
    color: #fff;
    background-color: #247878;
    border-color: #247878; }

.list-group-item-eos {
  color: #0f0f10;
  background-color: #d1d1d1; }
  .list-group-item-eos.list-group-item-action:hover, .list-group-item-eos.list-group-item-action:focus {
    color: #0f0f10;
    background-color: #bcbcbc; }
  .list-group-item-eos.list-group-item-action.active {
    color: #fff;
    background-color: #0f0f10;
    border-color: #0f0f10; }

.list-group-item-xmr {
  color: #993d00;
  background-color: #ffe0cc; }
  .list-group-item-xmr.list-group-item-action:hover, .list-group-item-xmr.list-group-item-action:focus {
    color: #993d00;
    background-color: #e6cab8; }
  .list-group-item-xmr.list-group-item-action.active {
    color: #fff;
    background-color: #993d00;
    border-color: #993d00; }

.list-group-item-xtz {
  color: #425900;
  background-color: #edf9cc; }
  .list-group-item-xtz.list-group-item-action:hover, .list-group-item-xtz.list-group-item-action:focus {
    color: #425900;
    background-color: #d5e0b8; }
  .list-group-item-xtz.list-group-item-action.active {
    color: #fff;
    background-color: #425900;
    border-color: #425900; }

.list-group-item-xem {
  color: #277347;
  background-color: #d9f2e4; }
  .list-group-item-xem.list-group-item-action:hover, .list-group-item-xem.list-group-item-action:focus {
    color: #277347;
    background-color: #c3dacd; }
  .list-group-item-xem.list-group-item-action.active {
    color: #fff;
    background-color: #277347;
    border-color: #277347; }

.list-group-item-neo {
  color: #357300;
  background-color: #def2cc; }
  .list-group-item-neo.list-group-item-action:hover, .list-group-item-neo.list-group-item-action:focus {
    color: #357300;
    background-color: #c8dab8; }
  .list-group-item-neo.list-group-item-action.active {
    color: #fff;
    background-color: #357300;
    border-color: #357300; }

.list-group-item-dash {
  color: #114671;
  background-color: #d2e3f2; }
  .list-group-item-dash.list-group-item-action:hover, .list-group-item-dash.list-group-item-action:focus {
    color: #114671;
    background-color: #bdccda; }
  .list-group-item-dash.list-group-item-action.active {
    color: #fff;
    background-color: #114671;
    border-color: #114671; }

.list-group-item-etc {
  color: #3d5645;
  background-color: #e0e9e3; }
  .list-group-item-etc.list-group-item-action:hover, .list-group-item-etc.list-group-item-action:focus {
    color: #3d5645;
    background-color: #cad2cc; }
  .list-group-item-etc.list-group-item-action.active {
    color: #fff;
    background-color: #3d5645;
    border-color: #3d5645; }

.list-group-item-dgb {
  color: #003d7a;
  background-color: #cce0f5; }
  .list-group-item-dgb.list-group-item-action:hover, .list-group-item-dgb.list-group-item-action:focus {
    color: #003d7a;
    background-color: #b8cadd; }
  .list-group-item-dgb.list-group-item-action.active {
    color: #fff;
    background-color: #003d7a;
    border-color: #003d7a; }

.list-group-item-dcr {
  color: #234a97;
  background-color: #d8e5fe; }
  .list-group-item-dcr.list-group-item-action:hover, .list-group-item-dcr.list-group-item-action:focus {
    color: #234a97;
    background-color: #c2cee5; }
  .list-group-item-dcr.list-group-item-action.active {
    color: #fff;
    background-color: #234a97;
    border-color: #234a97; }

.list-group-item-rep {
  color: #26617a;
  background-color: #d9ecf5; }
  .list-group-item-rep.list-group-item-action:hover, .list-group-item-rep.list-group-item-action:focus {
    color: #26617a;
    background-color: #c3d4dd; }
  .list-group-item-rep.list-group-item-action.active {
    color: #fff;
    background-color: #26617a;
    border-color: #26617a; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 4px;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: none;
    box-shadow: 0 0 0 0rem rgba(97, 61, 230, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 4px; }
  .toast:not(.showing):not(.show) {
    opacity: 0; }
  .toast.hide {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 15px; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #eff2f7;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #eff2f7;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Spartan", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Spartan", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[data-popper-placement^="top"] {
  margin-bottom: 0.5rem !important; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-end, .bs-popover-auto[data-popper-placement^="right"] {
  margin-left: 0.5rem !important; }
  .bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[data-popper-placement^="bottom"] {
  margin-top: 0.5rem !important; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0; }

.bs-popover-start, .bs-popover-auto[data-popper-placement^="left"] {
  margin-right: 0.5rem !important; }
  .bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #1f2c73;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #7184ad; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators li {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #613de6; }
  .link-primary:hover, .link-primary:focus {
    color: #4e31b8; }

.link-secondary {
  color: #eff2f7; }
  .link-secondary:hover, .link-secondary:focus {
    color: #f2f5f9; }

.link-success {
  color: #36b37e; }
  .link-success:hover, .link-success:focus {
    color: #5ec298; }

.link-info {
  color: #00b8d9; }
  .link-info:hover, .link-info:focus {
    color: #33c6e1; }

.link-warning {
  color: #ffab00; }
  .link-warning:hover, .link-warning:focus {
    color: #ffbc33; }

.link-danger {
  color: #ff5630; }
  .link-danger:hover, .link-danger:focus {
    color: #ff7859; }

.link-light {
  color: #f6f9fc; }
  .link-light:hover, .link-light:focus {
    color: #f8fafd; }

.link-dark {
  color: #1e2e50; }
  .link-dark:hover, .link-dark:focus {
    color: #182540; }

.link-btc {
  color: #f7931a; }
  .link-btc:hover, .link-btc:focus {
    color: #f9a948; }

.link-eth {
  color: #282828; }
  .link-eth:hover, .link-eth:focus {
    color: #202020; }

.link-usdt {
  color: #2ca07a; }
  .link-usdt:hover, .link-usdt:focus {
    color: #56b395; }

.link-xrp {
  color: #346aa9; }
  .link-xrp:hover, .link-xrp:focus {
    color: #2a5587; }

.link-ltc {
  color: #838383; }
  .link-ltc:hover, .link-ltc:focus {
    color: #9c9c9c; }

.link-ada {
  color: #3cc8c8; }
  .link-ada:hover, .link-ada:focus {
    color: #63d3d3; }

.link-eos {
  color: #19191a; }
  .link-eos:hover, .link-eos:focus {
    color: #141415; }

.link-xmr {
  color: #ff6600; }
  .link-xmr:hover, .link-xmr:focus {
    color: #ff8533; }

.link-xtz {
  color: #a6df00; }
  .link-xtz:hover, .link-xtz:focus {
    color: #b8e533; }

.link-xem {
  color: #41bf76; }
  .link-xem:hover, .link-xem:focus {
    color: #67cc91; }

.link-neo {
  color: #58bf00; }
  .link-neo:hover, .link-neo:focus {
    color: #79cc33; }

.link-dash {
  color: #1c75bc; }
  .link-dash:hover, .link-dash:focus {
    color: #165e96; }

.link-etc {
  color: #669073; }
  .link-etc:hover, .link-etc:focus {
    color: #85a68f; }

.link-dgb {
  color: #0066cc; }
  .link-dgb:hover, .link-dgb:focus {
    color: #0052a3; }

.link-dcr {
  color: #3b7cfb; }
  .link-dcr:hover, .link-dcr:focus {
    color: #6296fc; }

.link-rep {
  color: #40a2cb; }
  .link-rep:hover, .link-rep:focus {
    color: #66b5d5; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --aspect-ratio: 100%; }

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #eff2f7 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #eff2f7 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #eff2f7 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #eff2f7 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #eff2f7 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #613de6 !important; }

.border-secondary {
  border-color: #eff2f7 !important; }

.border-success {
  border-color: #36b37e !important; }

.border-info {
  border-color: #00b8d9 !important; }

.border-warning {
  border-color: #ffab00 !important; }

.border-danger {
  border-color: #ff5630 !important; }

.border-light {
  border-color: #f6f9fc !important; }

.border-dark {
  border-color: #1e2e50 !important; }

.border-btc {
  border-color: #f7931a !important; }

.border-eth {
  border-color: #282828 !important; }

.border-usdt {
  border-color: #2ca07a !important; }

.border-xrp {
  border-color: #346aa9 !important; }

.border-ltc {
  border-color: #838383 !important; }

.border-ada {
  border-color: #3cc8c8 !important; }

.border-eos {
  border-color: #19191a !important; }

.border-xmr {
  border-color: #ff6600 !important; }

.border-xtz {
  border-color: #a6df00 !important; }

.border-xem {
  border-color: #41bf76 !important; }

.border-neo {
  border-color: #58bf00 !important; }

.border-dash {
  border-color: #1c75bc !important; }

.border-etc {
  border-color: #669073 !important; }

.border-dgb {
  border-color: #0066cc !important; }

.border-dcr {
  border-color: #3b7cfb !important; }

.border-rep {
  border-color: #40a2cb !important; }

.border-white {
  border-color: #fff !important; }

.border-0 {
  border-width: 0 !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important; }

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-4 {
  font-size: 1.125rem !important; }

.fs-5 {
  font-size: 1rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-primary {
  color: #613de6 !important; }

.text-secondary {
  color: #eff2f7 !important; }

.text-success {
  color: #36b37e !important; }

.text-info {
  color: #00b8d9 !important; }

.text-warning {
  color: #ffab00 !important; }

.text-danger {
  color: #ff5630 !important; }

.text-light {
  color: #f6f9fc !important; }

.text-dark {
  color: #1e2e50 !important; }

.text-btc {
  color: #f7931a !important; }

.text-eth {
  color: #282828 !important; }

.text-usdt {
  color: #2ca07a !important; }

.text-xrp {
  color: #346aa9 !important; }

.text-ltc {
  color: #838383 !important; }

.text-ada {
  color: #3cc8c8 !important; }

.text-eos {
  color: #19191a !important; }

.text-xmr {
  color: #ff6600 !important; }

.text-xtz {
  color: #a6df00 !important; }

.text-xem {
  color: #41bf76 !important; }

.text-neo {
  color: #58bf00 !important; }

.text-dash {
  color: #1c75bc !important; }

.text-etc {
  color: #669073 !important; }

.text-dgb {
  color: #0066cc !important; }

.text-dcr {
  color: #3b7cfb !important; }

.text-rep {
  color: #40a2cb !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #7184ad !important; }

.text-muted {
  color: #7184ad !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #613de6 !important; }

.bg-secondary {
  background-color: #eff2f7 !important; }

.bg-success {
  background-color: #36b37e !important; }

.bg-info {
  background-color: #00b8d9 !important; }

.bg-warning {
  background-color: #ffab00 !important; }

.bg-danger {
  background-color: #ff5630 !important; }

.bg-light {
  background-color: #f6f9fc !important; }

.bg-dark {
  background-color: #1e2e50 !important; }

.bg-btc {
  background-color: #f7931a !important; }

.bg-eth {
  background-color: #282828 !important; }

.bg-usdt {
  background-color: #2ca07a !important; }

.bg-xrp {
  background-color: #346aa9 !important; }

.bg-ltc {
  background-color: #838383 !important; }

.bg-ada {
  background-color: #3cc8c8 !important; }

.bg-eos {
  background-color: #19191a !important; }

.bg-xmr {
  background-color: #ff6600 !important; }

.bg-xtz {
  background-color: #a6df00 !important; }

.bg-xem {
  background-color: #41bf76 !important; }

.bg-neo {
  background-color: #58bf00 !important; }

.bg-dash {
  background-color: #1c75bc !important; }

.bg-etc {
  background-color: #669073 !important; }

.bg-dgb {
  background-color: #0066cc !important; }

.bg-dcr {
  background-color: #3b7cfb !important; }

.bg-rep {
  background-color: #40a2cb !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 4px !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 4px !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important; }

.rounded-end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important; }

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }

.rounded-start {
  border-bottom-left-radius: 4px !important;
  border-top-left-radius: 4px !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.875rem !important; }
  .fs-3 {
    font-size: 1.5rem !important; }
  .fs-sm-1 {
    font-size: 2.25rem !important; }
  .fs-sm-2 {
    font-size: 1.875rem !important; }
  .fs-sm-3 {
    font-size: 1.5rem !important; }
  .fs-md-1 {
    font-size: 2.25rem !important; }
  .fs-md-2 {
    font-size: 1.875rem !important; }
  .fs-md-3 {
    font-size: 1.5rem !important; }
  .fs-lg-1 {
    font-size: 2.25rem !important; }
  .fs-lg-2 {
    font-size: 1.875rem !important; }
  .fs-lg-3 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

* {
  outline: none;
  padding: 0; }
  *::after {
    margin: 0;
    padding: 0; }
  *::before {
    margin: 0;
    padding: 0; }

body.dashboard {
  background: #f6f9fc;
  font-size: 14px; }

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 80px; }
  #main-wrapper.show {
    opacity: 1; }

.content-body {
  margin-left: 100px;
  margin-top: 15px; }
  @media only screen and (max-width: 767px) {
    .content-body {
      margin-left: 0px;
      margin-bottom: 50px; } }

ul {
  padding: 0;
  margin: 0; }

li {
  list-style: none; }

a {
  color: #613de6;
  text-decoration: none;
  outline: none; }
  a:hover, a:focus, a.active {
    text-decoration: none;
    outline: none;
    color: #613de6; }

b,
strong {
  color: #1e2e50; }

/* Editable */
@media (min-width: 1400px) {
  
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 80%; } }

.copy {
  cursor: pointer; }

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 999999999; }
  #preloader i {
    display: block;
    width: 16px;
    height: 16px;
    background: black;
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    opacity: 1;
    -webkit-transform: translate3d(60px, 0, 0);
    overflow: hidden;
    text-indent: -9999px;
    border: 1px solid white; }
    #preloader i:nth-child(1) {
      background: #613de6;
      -webkit-animation: googleDotA 1.75s ease-in-out infinite; }
    #preloader i:nth-child(2) {
      background: #36b37e;
      -webkit-animation: googleDotB 1.75s ease-in-out infinite; }
    #preloader i:nth-child(3) {
      background: #ffab00;
      -webkit-animation: googleDotC 1.75s ease-in-out infinite; }

@-webkit-keyframes googleDotA {
  0% {
    opacity: 0;
    transform: translate3d(60px, 0, 0);
    -webkit-transform: translate3d(60px, 0, 0); }
  30% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  70% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(-300px, 0, 0);
    -webkit-transform: translate3d(-300px, 0, 0); } }

@-webkit-keyframes googleDotB {
  0% {
    opacity: 0;
    transform: translate3d(180px, 0, 0);
    -webkit-transform: translate3d(180px, 0, 0); }
  35% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  77% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(-180px, 0, 0);
    -webkit-transform: translate3d(-180px, 0, 0); } }

@-webkit-keyframes googleDotC {
  0% {
    opacity: 0;
    transform: translate3d(300px, 0, 0);
    -webkit-transform: translate3d(300px, 0, 0); }
  40% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  80% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0); }
  100% {
    opacity: 0;
    transform: translate3d(-60px, 0, 0);
    -webkit-transform: translate3d(-60px, 0, 0); } }

.section-padding {
  padding: 100px 0; }
  @media only screen and (max-width: 1199px) {
    .section-padding {
      padding: 80px 0; } }
  @media only screen and (max-width: 991px) {
    .section-padding {
      padding: 75px 0; } }
  @media only screen and (max-width: 767px) {
    .section-padding {
      padding: 60px 0; } }
  @media only screen and (max-width: 575px) {
    .section-padding {
      padding: 50px 0; } }

.mt-80 {
  margin-top: 80px; }

.mb-80 {
  margin-bottom: 80px; }

.breadcrumbs {
  display: flex;
  justify-content: flex-end; }
  .breadcrumbs li a {
    color: #7184ad;
    display: inline-block;
    margin-left: 15px;
    font-size: 14px; }
  .breadcrumbs li.active a {
    color: #fff; }

::-webkit-scrollbar {
  width: 5px; }

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777; }

.c-pointer {
  cursor: pointer; }

.trade-balance {
  position: relative;
  height: 380px; }

.position-value {
  position: relative;
  height: 320px; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(35, 38, 45, 0.09); }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(35, 38, 45, 0.275); }

.section-title {
  margin-bottom: 75px; }
  .section-title h2, .section-title .h2 {
    text-align: center;
    font-weight: 600; }

#map-canvas {
  height: 500px; }

.header {
  padding: 20px 0px;
  position: fixed;
  top: 0;
  left: 100px;
  right: 0;
  z-index: 02;
  background: #fff; }
  .header.bg-primary {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important; }
  @media only screen and (max-width: 767px) {
    .header {
      left: 0;
      padding: 10px 0px; } }
  .header .brand-logo {
    margin-top: 0px;
    padding-right: 20px;
    border-radius: 5px; }
    @media only screen and (max-width: 767px) {
      .header .brand-logo {
        display: block; }
        .header .brand-logo span {
          display: none; } }
  .header.landing {
    padding: 20px 0px;
    left: 0px; }
    .header.landing a {
      display: flex;
      align-items: center;
      justify-content: center; }
    .header.landing .brand-logo {
      display: block; }
      .header.landing .brand-logo img {
        margin-right: 10px; }
      .header.landing .brand-logo span {
        display: block;
        top: 0; }

.header-content,
.header-left,
.header-right {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.notification {
  cursor: pointer; }
  .notification .notify-bell {
    margin-right: 30px; }
    @media only screen and (max-width: 767px) {
      .notification .notify-bell {
        margin-right: 15px;
        margin-left: 15px; } }
    .notification .notify-bell i {
      font-size: 20px;
      color: #1e2e50; }
  .notification .dropdown-menu {
    border: 0px;
    padding: 15px 20px 10px;
    margin: 0px;
    top: 25px !important;
    width: 330px;
    box-shadow: 0 36px 48px rgba(27, 25, 148, 0.08);
    border-radius: 5px; }
    .notification .dropdown-menu h4, .notification .dropdown-menu .h4 {
      border-bottom: 1px solid #eff2f7;
      padding-bottom: 15px;
      font-size: 16px; }
    .notification .dropdown-menu a {
      display: inline-block;
      border-bottom: 1px solid #eff2f7;
      padding: 10px 0px; }
      .notification .dropdown-menu a:last-child {
        border: 0px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: #1e2e50; }
        .notification .dropdown-menu a:last-child i {
          margin-left: 5px;
          font-size: 18px; }
      .notification .dropdown-menu a p {
        margin-bottom: 0px;
        color: #1f2c73;
        font-weight: 600;
        font-size: 14px; }
      .notification .dropdown-menu a span {
        font-size: 13px;
        color: #7184ad; }
      .notification .dropdown-menu a span.icon {
        height: 40px;
        width: 40px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px; }
        .notification .dropdown-menu a span.icon i {
          font-size: 20px; }
      .notification .dropdown-menu a span.success {
        background: #36b37e; }
      .notification .dropdown-menu a span.fail {
        background: #ff5630; }
      .notification .dropdown-menu a span.pending {
        background: #ffab00; }

.dropdown-toggle::after {
  border-top: 0px;
  margin-left: 10.2px;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free';
  font-weight: 700;
  content: "\f107"; }

.profile_log {
  cursor: pointer; }
  .profile_log .user {
    display: flex;
    align-items: center; }
    .profile_log .user .thumb {
      height: 35px;
      width: 35px;
      border-radius: 50px;
      color: #fff;
      text-align: center; }
      .profile_log .user .thumb img {
        max-width: 35px; }
    .profile_log .user .arrow i {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-top: 6px;
      display: inline-block; }
    @media only screen and (max-width: 1199px) {
      .profile_log .user .arrow {
        display: none; } }
  .profile_log .dropdown-menu {
    border: 0px;
    padding: 0px;
    margin: 0px;
    top: 25px !important;
    box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
    border-radius: 5px;
    background-color: #fff;
    min-width: 240px; }
    .profile_log .dropdown-menu .user-email {
      padding: 20px 20px 10px; }
      .profile_log .dropdown-menu .user-email .thumb {
        margin-right: 10px; }
      .profile_log .dropdown-menu .user-email .user-info {
        margin: 0px; }
      .profile_log .dropdown-menu .user-email h5, .profile_log .dropdown-menu .user-email .h5 {
        margin-bottom: 0px; }
      .profile_log .dropdown-menu .user-email span {
        font-size: 14px; }
    .profile_log .dropdown-menu .user-balance {
      display: flex;
      justify-content: space-around;
      margin-bottom: 15px; }
      .profile_log .dropdown-menu .user-balance p {
        margin-bottom: 0px;
        font-weight: 500;
        color: #1f2c73; }
    .profile_log .dropdown-menu .dropdown-item {
      padding: 10px 20px;
      border-top: 1px solid #eff2f7;
      font-weight: 400;
      display: flex;
      align-items: center; }
      .profile_log .dropdown-menu .dropdown-item:first-child {
        border: 0px; }
      .profile_log .dropdown-menu .dropdown-item.logout {
        color: #ff5630; }
        .profile_log .dropdown-menu .dropdown-item.logout i {
          color: #ff5630; }
      .profile_log .dropdown-menu .dropdown-item i {
        margin-right: 10px;
        font-size: 18px;
        color: #613de6;
        font-weight: bold; }
      .profile_log .dropdown-menu .dropdown-item:hover, .profile_log .dropdown-menu .dropdown-item:focus, .profile_log .dropdown-menu .dropdown-item.active {
        background-color: #613de6;
        color: #fff; }
        .profile_log .dropdown-menu .dropdown-item:hover i, .profile_log .dropdown-menu .dropdown-item:focus i, .profile_log .dropdown-menu .dropdown-item.active i {
          color: #fff; }

.dark-light-toggle {
  margin-right: 20px;
  cursor: pointer;
  display: none; }
  @media only screen and (max-width: 767px) {
    .dark-light-toggle {
      margin-right: 0px;
      margin-left: 15px; } }
  .dark-light-toggle i {
    font-size: 20px; }
  .dark-light-toggle .light {
    display: none; }

.dark-theme .dark {
  display: none; }

.dark-theme .light {
  display: block;
  color: #ffab00; }

.navigation .navbar {
  background-color: transparent !important;
  padding: 0px; }
  .navigation .navbar ul {
    align-items: center;
    margin-left: auto; }
    @media only screen and (max-width: 991px) {
      .navigation .navbar ul {
        margin-top: 15px; } }
    .navigation .navbar ul > li {
      display: inline-block;
      padding: 0px 7px;
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .navigation .navbar ul > li {
          border-bottom: 1px solid #eff2f7;
          margin: 0 15px;
          padding: 7px 15px; } }
      .navigation .navbar ul > li > a {
        font-size: 16px;
        font-weight: 600;
        color: #1e2e50; }
        @media only screen and (max-width: 991px) {
          .navigation .navbar ul > li > a::after {
            position: absolute;
            right: 15px;
            top: 25px; } }
    @media only screen and (min-width: 991px) {
      .navigation .navbar ul .dropdown > a {
        position: relative;
        transition: all 0.2s ease-in-out; }
      .navigation .navbar ul .dropdown-menu {
        background: #fff;
        padding: 15px 0;
        display: block;
        opacity: 0;
        visibility: hidden;
        z-index: 1030;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.15);
        border: 0px;
        margin-top: 10px;
        z-index: 998;
        min-width: 10rem; }
        .navigation .navbar ul .dropdown-menu a {
          display: inline-block;
          color: #7184ad; }
          .navigation .navbar ul .dropdown-menu a:hover, .navigation .navbar ul .dropdown-menu a:focus, .navigation .navbar ul .dropdown-menu a:active, .navigation .navbar ul .dropdown-menu a.active {
            color: #613de6;
            background: transparent; }
      .navigation .navbar ul li.dropdown:hover a::before {
        opacity: 1;
        bottom: -9px; }
      .navigation .navbar ul li.dropdown:hover .dropdown-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0px; } }
    @media only screen and (max-width: 991px) {
      .navigation .navbar ul .dropdown-menu {
        border: 0px;
        padding: 0px; }
        .navigation .navbar ul .dropdown-menu a {
          border-bottom: 1px solid #eff2f7;
          padding: 15px;
          color: #7184ad; }
          .navigation .navbar ul .dropdown-menu a:last-child {
            border: 0px; }
          .navigation .navbar ul .dropdown-menu a i,
          .navigation .navbar ul .dropdown-menu a h6,
          .navigation .navbar ul .dropdown-menu a .h6 {
            color: #613de6; }
          .navigation .navbar ul .dropdown-menu a p {
            color: #7184ad;
            margin-bottom: 0; } }

.signin-btn {
  min-width: 100px; }

.logo-white {
  display: none; }

.light .logo-primary {
  display: none; }

.light .logo-white {
  display: block; }

.light .navigation .navbar ul > li > a {
  color: rgba(255, 255, 255, 0.85); }
  .light .navigation .navbar ul > li > a:hover, .light .navigation .navbar ul > li > a:focus, .light .navigation .navbar ul > li > a.active, .light .navigation .navbar ul > li > a:active {
    color: white; }

.light .signin-btn .btn-primary {
  background: #fff;
  color: #1e2e50; }

.bottom {
  background: #1e2e50; }
  .bottom .bottom-logo p {
    color: #7184ad;
    line-height: 30px;
    font-weight: 400; }
  @media only screen and (max-width: 991px) {
    .bottom .bottom-widget {
      margin-bottom: 30px; } }
  .bottom .bottom-widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 20px; }
  .bottom .bottom-widget ul li a {
    color: #7184ad;
    font-weight: 400;
    margin-bottom: 10px;
    display: inline-block; }

.footer {
  background: #1e2e50;
  padding: 27px 0px; }
  .footer .copyright {
    position: relative;
    text-align: left; }
    .footer .copyright p {
      margin: 0;
      font-size: 14px;
      color: #7184ad; }
      .footer .copyright p a {
        color: #fff;
        font-weight: 700; }
  .footer .footer-social {
    text-align: right; }
    .footer .footer-social li {
      display: inline-block; }
      .footer .footer-social li a {
        color: rgba(255, 255, 255, 0.8);
        padding: 0px 10px; }

.sidebar {
  background: #fff;
  position: fixed;
  left: 0px;
  height: calc(100%-20px);
  width: 85px;
  top: 0px;
  z-index: 3;
  bottom: 0px;
  border-radius: 0px;
  border-right: 1px solid #eff2f7; }
  @media only screen and (max-width: 767px) {
    .sidebar {
      top: auto;
      bottom: 0;
      width: 100%;
      height: 50px;
      left: 0;
      border-radius: 0px; } }

.brand-logo {
  text-align: center;
  margin-top: 30px; }
  @media only screen and (max-width: 767px) {
    .brand-logo {
      display: none; } }

.menu {
  margin-top: 100px; }
  @media only screen and (max-width: 767px) {
    .menu {
      margin-top: 0px; } }
  @media only screen and (max-width: 767px) {
    .menu ul {
      display: flex;
      justify-content: space-around;
      align-items: center; } }
  .menu ul li {
    text-align: center; }
    .menu ul li a {
      padding: 12px 15px;
      display: inline-block;
      margin-bottom: 20px;
      border-radius: 9px; }
      .menu ul li a:hover, .menu ul li a:focus, .menu ul li a:active {
        color: #1e2e50;
        opacity: 1; }
        .menu ul li a:hover i, .menu ul li a:focus i, .menu ul li a:active i {
          color: #1e2e50;
          opacity: 1; }
      .menu ul li a.active i {
        color: #1e2e50;
        opacity: 1; }
      .menu ul li a i {
        color: #7184ad;
        font-size: 24px; }
    .menu ul li.logout {
      position: absolute;
      bottom: 50px;
      left: 0;
      right: 0; }
      @media only screen and (max-width: 767px) {
        .menu ul li.logout {
          position: relative;
          bottom: 0;
          left: auto;
          right: auto; } }

.copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  color: #fff; }
  .copyright a {
    color: #fff;
    font-size: 12px; }
  @media only screen and (max-width: 767px) {
    .copyright {
      display: none; } }

.dark-theme {
  background-color: #1c0c5b;
  color: #bdaef5; }
  .dark-theme h1, .dark-theme .h1,
  .dark-theme .h1,
  .dark-theme h2,
  .dark-theme .h2,
  .dark-theme .h2,
  .dark-theme h3,
  .dark-theme .h3,
  .dark-theme .h3,
  .dark-theme h4,
  .dark-theme .h4,
  .dark-theme .h4,
  .dark-theme h5,
  .dark-theme .h5,
  .dark-theme .h5,
  .dark-theme h6,
  .dark-theme .h6,
  .dark-theme .h6 {
    color: #fff; }
  .dark-theme b,
  .dark-theme strong {
    color: #fff; }
  .dark-theme .header {
    background-color: #1c0c5b; }
  .dark-theme .dropdown-menu {
    background-color: #26107a; }
    .dark-theme .dropdown-menu h4, .dark-theme .dropdown-menu .h4 {
      border-color: rgba(255, 255, 255, 0.08); }
    .dark-theme .dropdown-menu p {
      color: #fff !important; }
    .dark-theme .dropdown-menu a {
      border-color: rgba(255, 255, 255, 0.08); }
      .dark-theme .dropdown-menu a span {
        color: #bdaef5 !important; }
        .dark-theme .dropdown-menu a span i {
          color: #fff; }
  .dark-theme .notification .notify-bell i {
    color: #fff; }
  .dark-theme .notification .dropdown-menu a:last-child {
    color: #fff; }
  .dark-theme .profile_log .dropdown-menu .dropdown-item {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    color: #bdaef5; }
  .dark-theme .user-info span,
  .dark-theme .user-balance span {
    color: #bdaef5; }
  .dark-theme .card {
    background: #26107a !important; }
    .dark-theme .card .card-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.08); }
      .dark-theme .card .card-header .card-title {
        color: #fff; }
  .dark-theme .trade-form p {
    color: #fff; }
  .dark-theme .balance-widget li .icon-title span {
    color: #fff; }
  .dark-theme .form-control,
  .dark-theme .form-select {
    background: #1c0c5b;
    border-color: rgba(255, 255, 255, 0.08);
    color: #bdaef5; }
    .dark-theme .form-control::-webkit-input-placeholder,
    .dark-theme .form-select::-webkit-input-placeholder {
      color: #bdaef5; }
    .dark-theme .form-control:-ms-input-placeholder,
    .dark-theme .form-select:-ms-input-placeholder {
      color: #bdaef5; }
    .dark-theme .form-control::placeholder,
    .dark-theme .form-select::placeholder {
      color: #bdaef5; }
    .dark-theme .form-control:hover,
    .dark-theme .form-select:hover {
      border-color: rgba(255, 255, 255, 0.08);
      color: #bdaef5;
      background: #1c0c5b; }
    .dark-theme .form-control:focus, .dark-theme .form-control:active, .dark-theme .form-control.active,
    .dark-theme .form-select:focus,
    .dark-theme .form-select:active,
    .dark-theme .form-select.active {
      border-color: rgba(255, 255, 255, 0.08);
      color: #bdaef5;
      background: #1c0c5b; }
  .dark-theme .form-file-text {
    background-color: #1c0c5b;
    border-color: rgba(255, 255, 255, 0.08);
    color: #bdaef5; }
  .dark-theme .form-file-button {
    border-color: rgba(255, 255, 255, 0.08);
    background: #613de6;
    color: #fff; }
  .dark-theme .input-group-text {
    background: #26107a;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.08); }
  .dark-theme .btn-outline-primary,
  .dark-theme .btn-outline-secondary,
  .dark-theme .btn-outline-success,
  .dark-theme .btn-outline-info,
  .dark-theme .btn-outline-warning,
  .dark-theme .btn-outline-danger,
  .dark-theme .btn-outline-light {
    color: #fff; }
  .dark-theme .balance-widget li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08); }
  .dark-theme .table {
    color: #bdaef5; }
    .dark-theme .table th {
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08) !important; }
    .dark-theme .table td {
      border-color: rgba(255, 255, 255, 0.08); }
  .dark-theme .table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: $dbg;
    background-color: #1c0c5b;
    color: #bdaef5; }
  .dark-theme .settings-menu a {
    color: #bdaef5; }
    .dark-theme .settings-menu a:hover, .dark-theme .settings-menu a:focus, .dark-theme .settings-menu a:active, .dark-theme .settings-menu a.active {
      color: #fff; }
  .dark-theme .modal-content {
    background-color: #26107a; }
    .dark-theme .modal-content .modal-header {
      border-color: rgba(255, 255, 255, 0.08); }
      .dark-theme .modal-content .modal-header .btn-close {
        color: #fff; }
  .dark-theme .qr-img {
    background: #fff; }
  .dark-theme .chart-stat {
    border-color: rgba(255, 255, 255, 0.08); }

.intro-content h1, .intro-content .h1 {
  line-height: 55px;
  margin-bottom: 30px; }

.intro-content p {
  font-size: 20px;
  margin-bottom: 50px; }

.shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(./../images/svg/shape.svg);
  height: 160px;
  z-index: -1; }

.bg-primary .intro-btn .btn-primary {
  background: #fff;
  color: #613de6;
  border: 1px solid #431bd5; }

.bg-primary .intro-btn .btn-outline-primary {
  color: #fff;
  border: 1px solid #fff; }

.intro-form-exchange {
  padding: 30px;
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05);
  border-radius: 5px;
  background: #fff;
  z-index: 1;
  margin: 20px 0px; }
  .intro-form-exchange .nice-select {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important; }
  .intro-form-exchange .btn {
    padding: 10px 15px;
    font-weight: 600;
    position: relative;
    font-size: 16px; }
    .intro-form-exchange .btn i {
      font-size: 22px;
      font-weight: bold;
      position: absolute;
      right: 15px; }

.shape-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transform: translateZ(0);
  overflow: hidden; }

.shape-container[data-shape-position="bottom"] {
  top: auto;
  bottom: 0;
  margin-bottom: -6px; }

.shape-container svg {
  fill: #FFF;
  pointer-events: none;
  vertical-align: baseline; }

.intro-form {
  position: relative;
  max-width: 450px; }
  .intro-form form {
    display: flex; }
  .intro-form button {
    position: relative;
    background: #613de6;
    border-radius: 0.25rem;
    padding: 7px 20px;
    color: #fff;
    font-size: 24px;
    overflow: hidden;
    width: 60px;
    min-width: 60px;
    border: 0px;
    margin-left: 15px; }
    .intro-form button .first {
      position: absolute;
      right: 17px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out; }
    .intro-form button .second {
      position: absolute;
      left: -22px;
      top: 12px;
      z-index: 1;
      transition: all 0.2s ease-in-out; }
    .intro-form button:hover, .intro-form button:focus {
      outline: none; }
      .intro-form button:hover .first, .intro-form button:focus .first {
        right: -22px; }
      .intro-form button:hover .second, .intro-form button:focus .second {
        left: 20px; }

.intro-app-img {
  margin: 30px 0px;
  text-align: center; }

.testimonial .slick-initialized .slick-slide {
  display: flex; }

.testimonial-content {
  background: #fff;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
  border-radius: 5px; }

.customer-img img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative; }
  .customer-img img::before {
    position: absolute;
    content: ''; }

.customer-review {
  padding: 30px; }
  .customer-review img {
    width: auto !important;
    margin-bottom: 30px; }
  .customer-review p {
    margin-bottom: 0px; }

.customer-info h6, .customer-info .h6 {
  font-size: 18px; }

.testimonial2-content {
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05);
  padding: 50px 30px;
  margin: 20px 0px; }
  .testimonial2-content h3, .testimonial2-content .h3 {
    margin-bottom: 20px; }
  .testimonial2-content h5, .testimonial2-content .h5 {
    margin-bottom: 10px; }

.card {
  border: 0px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05);
  background: #fff; }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eff2f7;
    background: transparent;
    padding: 20px; }
  .card-title {
    font-size: 16px;
    margin-bottom: 0px;
    color: #1f2c73;
    font-weight: 600; }
  .card.transparent {
    background: transparent;
    box-shadow: none;
    border: 0px; }
    .card.transparent .card-header {
      border: 0px; }
    .card.transparent .card-body {
      padding: 0px; }
  .card .card-body {
    padding: 20px;
    background: transparent;
    border-radius: 15px; }
  .card .card-footer {
    padding: 20px;
    background: #fff; }

.form-label {
  font-weight: 600; }

.form-control {
  border-radius: 5px;
  height: 45px;
  border: 1px solid #eff2f7;
  padding: 0px 22px;
  font-size: 14px;
  color: #1e2e50;
  background: #fff; }
  .form-control span {
    margin-top: 0; }
  .form-control::-webkit-input-placeholder {
    color: #7184ad; }
  .form-control:-ms-input-placeholder {
    color: #7184ad; }
  .form-control::placeholder {
    color: #7184ad; }
  .form-control:hover {
    box-shadow: none !important;
    outline: none;
    border-color: #eff2f7;
    color: #1e2e50;
    background: #fff; }
  .form-control:focus, .form-control:active, .form-control.active {
    box-shadow: none !important;
    outline: none;
    border-color: #613de6;
    color: #1e2e50;
    background: #fff; }

.form-select {
  border-radius: 5px;
  height: 45px;
  border: 1px solid #eff2f7;
  padding: 0px 22px;
  font-size: 14px;
  color: #1e2e50; }
  .form-select:hover {
    box-shadow: none !important;
    outline: none;
    border-color: #eff2f7;
    color: #1e2e50; }
  .form-select:focus, .form-select:active, .form-select.active {
    box-shadow: none !important;
    outline: none;
    border-color: #613de6;
    color: #1e2e50; }

textarea.form-control {
  min-height: 100px; }

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important; }

.input-group-text {
  background: #fff;
  margin-bottom: 0px !important;
  color: #1e2e50; }

.input-group-append .input-group-text {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }

.input-group-prepend .input-group-text {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }

label.error {
  color: #ff5630;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400; }

.form-file {
  position: relative;
  margin-bottom: 3px; }
  .form-file .form-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0; }
  .form-file .form-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    display: flex;
    border-color: #ced4da;
    border-radius: 0.25rem; }
    .form-file .form-file-label .form-file-text {
      display: block;
      flex-grow: 1;
      padding: 0.375rem 0.75rem;
      overflow: hidden;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #fff;
      border-color: inherit;
      border-style: solid;
      border-width: 1px;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit; }
    .form-file .form-file-label .form-file-button {
      display: block;
      flex-shrink: 0;
      padding: 0.375rem 0.75rem;
      margin-left: -1px;
      line-height: 1.5;
      color: #495057;
      background-color: #e9ecef;
      border-color: inherit;
      border-style: solid;
      border-width: 1px;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
      border-left: 0; }

button,
.btn {
  color: #fff; }
  button:hover, button:focus, button:active,
  .btn:hover,
  .btn:focus,
  .btn:active {
    color: #fff; }

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-info,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-light {
  color: #1e2e50; }
  .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active,
  .btn-outline-secondary:hover,
  .btn-outline-secondary:focus,
  .btn-outline-secondary:active,
  .btn-outline-success:hover,
  .btn-outline-success:focus,
  .btn-outline-success:active,
  .btn-outline-info:hover,
  .btn-outline-info:focus,
  .btn-outline-info:active,
  .btn-outline-warning:hover,
  .btn-outline-warning:focus,
  .btn-outline-warning:active,
  .btn-outline-danger:hover,
  .btn-outline-danger:focus,
  .btn-outline-danger:active,
  .btn-outline-light:hover,
  .btn-outline-light:focus,
  .btn-outline-light:active {
    color: #fff; }

.btn-outline-dark {
  color: #1e2e50; }
  .btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active {
    color: #fff; }

.table th {
  color: #1f2c73;
  border-bottom: 1px solid #eff2f7 !important; }

.table-responsive-sm {
  min-width: 48rem; }

.table {
  margin-bottom: 0px; }
  .table tr:last-child td {
    border-bottom: 0px solid #eff2f7; }
  .table tr td,
  .table tr th {
    border-bottom: 1px solid #eff2f7;
    vertical-align: middle;
    padding: 18px; }
  .table tr td {
    font-weight: 600; }

.table-striped tr td,
.table-striped tr th {
  border: 0px !important; }

.table-striped > tbody > tr:nth-of-type(odd) td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px; }

.table-striped > tbody > tr:nth-of-type(odd) td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px; }

.welcome-profile {
  background: #613de6 !important; }
  .welcome-profile .card-body img {
    border: 4px solid #fff;
    border-radius: 100px;
    width: 60px; }
  .welcome-profile .card-body h4, .welcome-profile .card-body .h4 {
    color: #fff;
    margin-top: 10px;
    font-size: 18px; }
  .welcome-profile .card-body p {
    color: #fff;
    opacity: 0.75; }
  .welcome-profile .card-body ul {
    text-align: left; }
    .welcome-profile .card-body ul li {
      padding: 15px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
      .welcome-profile .card-body ul li:last-child {
        border: 0px;
        padding-bottom: 0px; }
      .welcome-profile .card-body ul li a {
        color: #f2f2f2;
        display: flex;
        align-items: center;
        transition: all 0.25 ease-in-out; }
        .welcome-profile .card-body ul li a span {
          background: #fff;
          padding: 5px;
          border-radius: 50px;
          width: 30px;
          height: 30px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          transition: all 0.25 ease-in-out; }
          .welcome-profile .card-body ul li a span.verified {
            color: #36b37e; }
          .welcome-profile .card-body ul li a span.not-verified {
            color: #ff5630; }

.trade-form p {
  font-size: 14px;
  color: #1f2c73; }
  .trade-form p a {
    color: #613de6; }

.balance-chart h4, .balance-chart .h4 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center; }

.balance-chart .apexcharts-canvas {
  margin: 0 auto; }

@media only screen and (max-width: 991px) {
  .balance-widget {
    margin-top: 30px; } }

.balance-widget li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  align-items: center;
  border-bottom: 1px solid #eff2f7;
  margin-bottom: 15px; }
  .balance-widget li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px; }
  .balance-widget li .icon-title {
    display: flex;
    align-items: center; }
    .balance-widget li .icon-title i {
      font-size: 30px;
      margin-right: 10px; }
    .balance-widget li .icon-title span {
      font-weight: 600;
      color: #1f2c73;
      font-size: 16px; }
      .balance-widget li .icon-title span small, .balance-widget li .icon-title span .small {
        opacity: 0.75;
        font-weight: normal;
        font-size: 14px; }

.invite-content .input-group-text {
  background: #613de6;
  color: #fff; }

.invite-content .social-share-link {
  margin-top: 15px; }
  .invite-content .social-share-link a {
    display: inline-block;
    margin-right: 10px; }
    .invite-content .social-share-link a i {
      font-size: 20px; }
      .invite-content .social-share-link a i.icofont-facebook {
        color: #3b5998; }
      .invite-content .social-share-link a i.icofont-twitter {
        color: #1da1f2; }
      .invite-content .social-share-link a i.icofont-whatsapp {
        color: #25d366; }
      .invite-content .social-share-link a i.icofont-telegram {
        color: #0088cc; }

.price-carousel .price-widget {
  background: #fff;
  border-radius: 10px;
  margin: 0px 15px;
  margin-bottom: 30px; }

.price-carousel button {
  background: #613de6;
  width: 30px;
  height: 30px; }
  .price-carousel button.slick-next:before {
    content: "\eab8";
    font-family: "IcoFont"; }
  .price-carousel button.slick-prev:before {
    font-family: "IcoFont";
    content: "\eab5"; }
  .price-carousel button:before {
    font-size: 24px; }
  .price-carousel button:hover, .price-carousel button:focus, .price-carousel button.active {
    background: #613de6; }

.price-widget {
  background: #fff;
  height: 120px;
  border-radius: 10px;
  margin: 0px 0px 30px;
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05);
  overflow: hidden;
  display: flex;
  align-items: center; }
  .price-widget a {
    display: inline-flex;
    color: #1e2e50;
    align-items: center;
    justify-content: space-between; }

.price-content {
  padding: 10px; }
  .price-content .icon-title {
    display: flex;
    align-items: center; }
  .price-content i {
    padding: 5px 7px;
    font-size: 24px;
    line-height: 40px; }
  .price-content span {
    color: #1e2e50;
    font-size: 18px;
    font-weight: 600; }
  .price-content h5, .price-content .h5 {
    color: #7184ad;
    margin-left: 10px;
    font-size: 14px; }

.home-chart .form-select {
  width: 150px;
  height: auto;
  border: 0px;
  padding: 0px;
  background-color: transparent; }

.chart-price-value {
  text-align: center;
  background: #fff;
  padding: 15px;
  border-radius: 10px; }
  .chart-price-value span {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px; }
  .chart-price-value h5, .chart-price-value .h5 {
    margin: 0px; }

.danger-arrow {
  display: flex;
  align-items: center; }
  .danger-arrow i {
    color: #ff5630;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 22px; }
  .danger-arrow span.bt-content {
    display: inherit; }

.success-arrow {
  display: flex;
  align-items: center; }
  .success-arrow i {
    color: #36b37e;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 22px; }
  .success-arrow .bt-content {
    display: inherit; }

.coin-name i {
  font-size: 20px;
  margin-right: 5px; }

.auth-form {
  padding: 30px 20px 10px; }

.privacy-link a {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #1e2e50; }

.identity-content {
  text-align: center; }
  .identity-content .icon {
    display: flex;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    margin: 0 auto;
    background: #fff;
    color: #613de6;
    font-size: 30px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    border: 1px solid #613de6; }
  .identity-content h4, .identity-content .h4 {
    max-width: 350px;
    margin: 0 auto 15px; }
  .identity-content p {
    max-width: 350px;
    margin: 0 auto 30px; }

.page-title {
  margin-bottom: 20px; }

.settings-menu a {
  display: inline-block;
  margin-right: 20px;
  color: #7184ad;
  font-weight: 600;
  font-size: 16px; }
  .settings-menu a:hover, .settings-menu a:focus, .settings-menu a:active, .settings-menu a.active {
    color: #613de6; }

.verify-content {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    .verify-content {
      display: block; }
      .verify-content .btn {
        margin-left: 65px;
        margin-top: 15px; } }

.icon-circle {
  height: 50px;
  width: 50px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px; }

.card .card {
  box-shadow: none;
  background: transparent; }

.chart-stat {
  border: 1px solid #eff2f7;
  margin: 15px 0px;
  padding: 15px;
  border-radius: 4px; }

.app-link p {
  margin-bottom: 30px; }

.app-link .btn {
  min-width: 150px; }

.user-info {
  margin: 15px 0px; }
  .user-info span {
    margin-bottom: 5px;
    display: inline-block; }

.wallet-widget {
  background: #fff;
  padding: 30px 15px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 15px; }
  .wallet-widget p {
    margin-bottom: 0px; }

.getstart-content {
  text-align: left;
  padding: 30px 30px;
  border-radius: 4px;
  margin: 20px 0px;
  background: #fff; }
  .getstart-content i {
    font-size: 36px;
    color: #6672e8; }
  .getstart-content h3, .getstart-content .h3 {
    margin-top: 20px;
    font-size: 18px; }
  .getstart-content a {
    color: #6672e8; }
    .getstart-content a i {
      font-size: 20px; }

.portfolio_list .media {
  margin: 50px 0px;
  display: flex;
  align-items: flex-start; }
  .portfolio_list .media h4, .portfolio_list .media .h4 {
    font-size: 22px;
    color: #1f2c73; }
  .portfolio_list .media .port-icon {
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 32px;
    margin-right: 20px;
    color: #613de6; }
    .portfolio_list .media .port-icon i {
      line-height: 0; }
  .portfolio_list .media p {
    margin-bottom: 0px;
    color: #7184ad;
    opacity: 0.85; }
    .portfolio_list .media p a {
      color: #fff;
      font-weight: bold; }

.portfolio_img {
  margin: 20px 0px; }
  .portfolio_img img {
    border-radius: 5px;
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1), 16px 40px 75px -40px rgba(0, 0, 0, 0.2); }

.trade-app-content {
  box-shadow: none; }
  .trade-app-content .card-body {
    padding: 30px;
    text-align: left; }
    .trade-app-content .card-body span {
      font-size: 36px;
      margin-bottom: 30px;
      display: inline-block;
      color: #6672e8;
      line-height: 0;
      padding: 10px;
      border-radius: 100px; }
    .trade-app-content .card-body h4, .trade-app-content .card-body .h4 {
      font-size: 20px;
      margin-bottom: 15px; }
    .trade-app-content .card-body p {
      min-height: 115px; }
      @media only screen and (max-width: 1199px) {
        .trade-app-content .card-body p {
          min-height: 165px; } }
      @media only screen and (max-width: 991px) {
        .trade-app-content .card-body p {
          min-height: auto; } }
    .trade-app-content .card-body a {
      color: #6672e8;
      display: inline-block;
      text-align: center;
      border-bottom: 2px solid #6672e8; }
      .trade-app-content .card-body a i {
        margin-left: 10px;
        font-size: 18px;
        font-weight: bold; }

.promo {
  background-color: #fff;
  border-bottom: 1px solid #eff2f7; }
  .promo .promo-content {
    text-align: left;
    margin: 50px 30px; }
    .promo .promo-content i {
      font-size: 36px;
      color: #6672e8; }
    .promo .promo-content h3, .promo .promo-content .h3 {
      margin-top: 20px;
      margin-bottom: 15px; }
    .promo .promo-content p {
      line-height: 30px; }
    .promo .promo-content a {
      color: #6672e8; }
      .promo .promo-content a i {
        font-size: 20px; }

.get-touch {
  border-bottom: 1px solid #eff2f7; }

.get-touch-content {
  padding: 30px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 5px; }
  .get-touch-content span {
    margin-right: 15px; }
    .get-touch-content span i {
      color: #6672e8;
      font-size: 42px; }
  .get-touch-content h4, .get-touch-content .h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .get-touch-content p {
    line-height: 27px; }

.trusted-logo img {
  width: 100px; }

.market-table table thead th {
  font-size: 14px; }

.market-table table tbody td,
.market-table table tbody th {
  border-color: #eff2f7;
  background: white; }

.market-table table tbody .coin_icon {
  display: flex;
  align-items: center; }
  .market-table table tbody .coin_icon i {
    font-size: 30px;
    margin-right: 10px;
    line-height: 0;
    top: 3px;
    position: relative; }
  .market-table table tbody .coin_icon span b {
    font-weight: 400;
    margin-left: 20px; }

.market-table table tbody .btn {
  font-size: 14px;
  padding: 5px;
  min-width: 59px; }

.info-content {
  background: #f6f9fc;
  text-align: center;
  padding: 50px 20px 70px !important;
  margin-bottom: 30px;
  border-radius: 4px; }
  .info-content i {
    font-size: 36px;
    color: #613de6; }
  .info-content h4, .info-content .h4 {
    margin-top: 15px;
    font-size: 17px;
    margin-bottom: 0px; }

.product-feature-text {
  margin-bottom: 50px; }
  .product-feature-text h4, .product-feature-text .h4 {
    font-size: 20px; }
    .product-feature-text h4 span, .product-feature-text .h4 span {
      margin-right: 10px;
      color: #6672e8;
      font-size: 30px; }

.product-feature-box {
  background: #fff;
  padding: 30px 15px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 5px;
  border: 1px solid #eff2f7; }
  .product-feature-box span {
    padding: 10px 15px;
    background: #613de6;
    color: #fff;
    display: inline-block;
    border-radius: 5px;
    margin-top: 5px;
    font-size: 24px; }
  .product-feature-box h4, .product-feature-box .h4 {
    margin: 20px 0 15px; }
  .product-feature-box p {
    padding: 0px 15px;
    margin: 0px; }

.new-product-content {
  box-shadow: 0px 20px 25px rgba(22, 28, 45, 0.05);
  padding: 30px 30px 50px;
  text-align: center;
  background: #fff; }
  .new-product-content img {
    max-width: 100px;
    margin-bottom: 30px; }
  .new-product-content h4, .new-product-content .h4 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px; }
  .new-product-content p {
    min-height: 110px; }
    @media only screen and (max-width: 991px) {
      .new-product-content p {
        min-height: 200px; } }
    @media only screen and (max-width: 767px) {
      .new-product-content p {
        min-height: auto; } }

.trust-content {
  margin: 25px 0;
  text-align: center;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.05);
  padding: 30px;
  min-height: 250px;
  border-bottom: 5px solid #6672e8;
  border-radius: 5px; }
  .trust-content span {
    margin-top: 20px;
    display: inline-block; }
    .trust-content span i {
      color: #6672e8;
      font-size: 36px; }
  .trust-content h4, .trust-content .h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .trust-content p {
    line-height: 27px; }

.cookie_alert .alert {
  margin-bottom: 0px;
  position: fixed;
  max-width: 385px;
  left: 15px;
  right: 0;
  bottom: 15px;
  z-index: 9999;
  box-shadow: 0 1.5rem 4rem rgba(47, 44, 216, 0.25);
  padding: 20px 30px; }

.cookie_alert .la-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer; }

.cookie_alert p {
  font-weight: 400;
  color: #1f2c73;
  line-height: 26px; }
  .cookie_alert p a {
    color: #613de6; }

.cookie_alert .btn {
  padding: 7px; }

.features-content {
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .features-content {
      margin-bottom: 50px; } }
  .features-content span i {
    color: #613de6;
    font-size: 36px; }
  .features-content h4, .features-content .h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .features-content p {
    line-height: 27px;
    max-width: 350px;
    margin: 0 auto 30px; }
  .features-content a {
    color: #613de6;
    font-size: 14px; }

.customer-support-content {
  text-align: left;
  margin: 50px 0px;
  background: #fff;
  padding: 50px 30px;
  min-height: 300px; }
  .customer-support-content span i {
    color: #613de6;
    font-size: 36px; }
  .customer-support-content h4, .customer-support-content .h4 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .customer-support-content p {
    line-height: 27px; }
  .customer-support-content a {
    color: #613de6;
    font-size: 14px; }

.intro-video-play {
  background-color: #fff;
  background-image: url("./../images/background/2.jpg");
  background-size: cover;
  background-position: 0 0;
  min-height: 465px;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  z-index: 1;
  margin-bottom: 30px;
  box-shadow: 0 1.5rem 4rem rgba(22, 28, 45, 0.1); }
  .intro-video-play::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #613de6;
    opacity: 0.45;
    border-radius: 5px;
    z-index: -1; }

.play-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }
  .play-btn a {
    background: #fff;
    width: 75px;
    height: 75px;
    border-radius: 100px;
    padding: 27px 0;
    text-align: center;
    cursor: pointer; }
    .play-btn a i {
      color: #613de6; }

.perfection-content {
  padding: 50px 30px;
  background: #fff;
  margin: 25px 0px;
  min-height: 200px; }
  .perfection-content p {
    margin-bottom: 0px; }

.advance-trading-content {
  padding: 50px 30px;
  background: #fff; }
  .advance-trading-content p {
    margin-bottom: 0px; }

.advance-trading-img img {
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05); }

.get-started-content {
  padding: 30px 30px;
  background: #fff;
  margin: 25px 0px;
  min-height: 200px; }
  .get-started-content h4, .get-started-content .h4 {
    margin-bottom: 20px; }
  .get-started-content p {
    margin-bottom: 0px; }

@media only screen and (max-width: 991px) {
  .appss-content {
    margin-top: 50px;
    margin-bottom: 50px; } }

.appss-content h2, .appss-content .h2 {
  margin-bottom: 30px;
  font-weight: 700; }

.appss-content ul li {
  padding: 10px 0;
  font-size: 17px; }
  .appss-content ul li i {
    font-weight: bold;
    font-size: 18px;
    color: #613de6;
    margin-right: 15px; }

.appss-content .btn {
  display: inline-block;
  padding: 10px 30px;
  margin-right: 15px; }

.wallet-feature .wallet-feature-content {
  margin-bottom: 50px;
  text-align: center;
  background: #fff;
  padding: 50px 15px 70px;
  box-shadow: 0px 1.25rem 1.5625rem rgba(22, 28, 45, 0.05);
  border-radius: 4px; }
  .wallet-feature .wallet-feature-content i {
    font-size: 40px;
    margin-bottom: 10px;
    color: #613de6;
    display: inline-block; }
  .wallet-feature .wallet-feature-content h4, .wallet-feature .wallet-feature-content .h4 {
    margin: 0 auto; }

.all-coin {
  border-bottom: 1px solid #eff2f7; }

.all-coins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .all-coins span {
    font-size: 48px;
    width: 75px;
    height: 75px;
    border: 1px solid #eff2f7;
    border-radius: 8px;
    text-align: center;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center; }

.all-coin-content {
  padding: 75px 0 0;
  text-align: center; }
  .all-coin-content h3, .all-coin-content .h3 {
    margin-bottom: 15px; }

.our_vision h3, .our_vision .h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 28px; }

.our_vision p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 30px; }

.service-img img {
  border-radius: 5px; }

.info-list ul li {
  margin-bottom: 7px;
  color: #7184ad;
  font-weight: 500;
  padding: 12px 0; }
  .info-list ul li:hover, .info-list ul li:focus {
    color: #613de6; }
  .info-list ul li i {
    margin-right: 15px;
    color: #613de6;
    font-weight: bold;
    font-size: 24px; }

.helpdesk-search .helpdesk-search-content {
  text-align: center; }

.helpdesk-search .helpdesk-form form {
  display: flex; }

.helpdesk-search .helpdesk-form .btn {
  margin-left: 20px;
  padding: 6px 30px; }

.help-category .help-cat {
  text-align: center;
  margin-bottom: 50px; }
  .help-category .help-cat span {
    font-size: 48px;
    color: #613de6; }
  .help-category .help-cat h4, .help-category .help-cat .h4 {
    font-size: 20px;
    margin-top: 15px; }

.help-category .sub-cat {
  display: inline-block;
  padding: 20px 30px;
  background: #f6f9fc;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 30px; }
  .help-category .sub-cat h5, .help-category .sub-cat .h5 {
    color: #1f2c73;
    margin-bottom: 0px; }

.troubleshooting-content h4, .troubleshooting-content .h4 {
  border-bottom: 1px solid #eff2f7;
  padding-bottom: 20px;
  margin-top: 50px; }

.troubleshooting-content ul {
  margin-top: 20px; }
  .troubleshooting-content ul li a {
    color: #7184ad;
    display: inline-block;
    padding-bottom: 15px; }
    .troubleshooting-content ul li a:hover, .troubleshooting-content ul li a:focus, .troubleshooting-content ul li a:active, .troubleshooting-content ul li a.active {
      color: #613de6; }

.ticket-box {
  background: #f6f9fc; }

.ticket-box-content {
  text-align: center; }
  .ticket-box-content p {
    padding: 10px 0 20px;
    margin-bottom: 0px;
    font-size: 18px; }
  .ticket-box-content .btn {
    min-width: 150px;
    font-weight: 600; }

.terms_condition {
  position: relative; }

.terms_condition-content {
  padding: 80px 0px 80px; }

.terms_condition-text {
  margin-bottom: 45px; }
  .terms_condition-text:last-child {
    margin-bottom: 0; }
  .terms_condition-text h3, .terms_condition-text .h3 {
    font-size: 24px;
    margin-bottom: 30px; }
  .terms_condition-text p {
    font-size: 16px;
    line-height: 30px;
    display: inline-block; }
  .terms_condition-text li {
    display: flex; }
    .terms_condition-text li i {
      line-height: 30px;
      margin-right: 15px;
      font-size: 13px;
      color: #1e2e50; }

.blog-grid .card {
  border: 0px;
  box-shadow: none;
  margin-bottom: 30px;
  background: #fff; }
  .blog-grid .card img {
    width: 100%; }
  .blog-grid .card .card-body {
    padding: 20px 0px !important; }
    .blog-grid .card .card-body .card-title {
      font-size: 18px;
      margin-bottom: 15px; }
    .blog-grid .card .card-body a {
      padding-bottom: 10px;
      border-bottom: 1px solid #613de6; }

.blog-sidebar .widget-search {
  position: relative; }
  .blog-sidebar .widget-search input {
    color: #677294;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #eff2f7;
    padding-left: 30px;
    height: 55px;
    z-index: 0; }
  .blog-sidebar .widget-search span {
    border: 0px;
    border-radius: 0px;
    font-size: 16px;
    color: #282835;
    background: transparent;
    position: absolute;
    right: 20px;
    top: 18px;
    padding: 0px;
    z-index: 1;
    cursor: pointer; }

.blog-sidebar .widget-recent-post {
  margin-top: 50px; }
  .blog-sidebar .widget-recent-post .post-title {
    font-size: 20px;
    border-bottom: 1px solid #eff2f7;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-recent-post .media img {
    max-width: 60px;
    border-radius: 5px;
    margin-right: 10px; }
  .blog-sidebar .widget-recent-post .media h5, .blog-sidebar .widget-recent-post .media .h5 {
    font-size: 16px; }
  .blog-sidebar .widget-recent-post .meta-info a {
    font-size: 14px;
    color: #7184ad;
    margin-right: 5px; }

.blog-sidebar .widget-category {
  margin-top: 50px; }
  .blog-sidebar .widget-category .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #eff2f7;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-category .list-group-item {
    border: 0px;
    color: #7184ad; }
    .blog-sidebar .widget-category .list-group-item .badge {
      background: #613de6;
      border-radius: 5px;
      padding: 5px 7px; }

.blog-sidebar .widget-tag {
  margin-top: 50px; }
  .blog-sidebar .widget-tag .widget-title {
    font-size: 20px;
    border-bottom: 1px solid #eff2f7;
    padding-bottom: 10px;
    margin-bottom: 30px; }
  .blog-sidebar .widget-tag .tag-group a {
    color: #7184ad;
    border: 1px solid #eff2f7;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin: 5px;
    font-size: 14px; }

.blog-single-details {
  float: left;
  width: 100%; }

.blog-single-post {
  float: left;
  width: 100%;
  cursor: pointer;
  position: relative; }
  .blog-single-post > ul.post-nfo {
    float: left;
    width: 100%;
    margin-bottom: 7px;
    margin-top: -1px; }

.post_info > ul.post-nfo {
  float: left;
  width: 100%;
  margin-bottom: 7px;
  margin-top: -1px; }

.post-nfo li {
  display: inline-block;
  margin-right: 20px; }
  .post-nfo li a {
    display: inline-block;
    color: #7184ad;
    font-size: 16px;
    margin-right: 20px;
    margin-right: 0; }
  .post-nfo li i {
    font-size: 16px;
    padding-right: 6px;
    position: relative;
    top: 1px; }

.pt {
  padding: 105px 0; }

.blog-single-sec.section-padding {
  padding: 110px 0 110px 0; }

.blog-single-post > h3, .blog-single-post > .h3 {
  color: #242526;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%; }

.post_info > h3, .post_info > .h3 {
  color: #242526;
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 23px;
  float: left;
  width: 100%; }
  .post_info > h3 a, .post_info > .h3 a {
    color: #242526; }

.blog-img {
  float: left;
  width: 100%;
  margin-bottom: 34px;
  position: relative; }
  .blog-img:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    background-color: rgba(127, 142, 233, 0.85);
    opacity: 0;
    visibility: hidden; }

.agent_img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background-color: rgba(127, 142, 233, 0.85);
  opacity: 0;
  visibility: hidden; }

.blog-single-post:hover .blog-img:before,
.agent:hover .agent_img:before {
  opacity: 1;
  visibility: visible;
  left: 0;
  width: 100%; }

.blog-single-post.single {
  cursor: inherit; }
  .blog-single-post.single .blog-img:before, .blog-single-post.single .blog-img:after {
    display: none; }

.blog-img > a img {
  width: 100%;
  float: left; }

.blog-single-post p {
  float: left;
  width: 100%;
  margin-bottom: 24px; }

.blog-single-post > blockquote {
  color: #1f2c73;
  font-size: 17px;
  font-weight: 600;
  line-height: 28px;
  border-left: 3px solid;
  padding: 2px 0 1px 18px;
  float: left;
  width: 100%;
  margin-bottom: 38px;
  margin-top: 13px; }

.blg-dv {
  float: left;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 8px; }

.blg-sm {
  float: left;
  width: 100%; }

.blg-info {
  float: left;
  width: 100%;
  padding-left: 27px; }

.bg-links {
  float: left;
  width: 100%;
  padding: 0 40px;
  margin-top: 5px;
  margin-bottom: 13px; }
  .bg-links li {
    float: left;
    width: 100%;
    color: #1f2c73;
    margin-bottom: 17px;
    position: relative;
    padding-left: 19px; }
    .bg-links li:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 100px;
      background-color: #242526;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%); }

/* ================ post-share =============== */
.post-share {
  float: left;
  width: 100%;
  padding: 21px 0;
  border-top: 1px solid #eff2f7;
  margin-top: 8px;
  margin-bottom: 27px; }

.social-links {
  float: left; }
  .social-links li {
    display: inline-block;
    margin-right: 17px; }
    .social-links li a {
      display: inline-block;
      color: #613de6;
      font-size: 14px; }

.post-share > a {
  float: right;
  color: #161616;
  font-size: 14px;
  font-weight: 400;
  margin-top: 3px; }
  .post-share > a i {
    padding-left: 8px; }

/* ============== cm-info-sec ================ */
.cm-info-sec {
  float: left;
  width: 100%;
  display: table;
  border: 1px solid #eff2f7;
  padding: 29px 24px;
  margin-bottom: 68px; }

.cm-img {
  display: table-cell; }
  .cm-img > img {
    float: left; }

.cm-info {
  display: table-cell;
  width: 100%;
  vertical-align: top;
  padding-left: 19px;
  position: relative;
  top: -1px; }
  .cm-info h3, .cm-info .h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px; }
  .cm-info > p {
    line-height: 24px;
    margin-bottom: 12px; }

/* ================= comment-section ================  */
.comment-section {
  float: left;
  width: 100%;
  margin-top: 7px; }

.p-title {
  color: #242526;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px; }

.comment-section .cm-info-sec {
  padding: 0;
  border: 0;
  margin: 0; }

.comment-section .cm-info {
  padding-top: 7px; }
  .comment-section .cm-info > h4, .comment-section .cm-info > .h4 {
    color: #7184ad;
    font-size: 14px;
    font-weight: 400; }
  .comment-section .cm-info h3, .comment-section .cm-info .h3 {
    margin-bottom: 3px; }

.comment-section > ul {
  float: left;
  width: 100%; }
  .comment-section > ul > li {
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 32px; }

.comment-section ul li p {
  float: left;
  width: 100%;
  margin-top: 13px; }

.cm-reply {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 7px;
  right: 0;
  text-transform: uppercase;
  letter-spacing: 1px; }

/* ================ post-comment-sec =============== */
.post-comment-sec {
  float: left;
  width: 100%;
  margin-top: 22px; }
  .post-comment-sec .p-title {
    margin-bottom: 18px; }
  .post-comment-sec > form {
    float: left;
    width: 100%; }
  .post-comment-sec .col-lg-4 {
    padding: 0 8px; }

.demo_img {
  text-align: center;
  margin-bottom: 60px; }
  .demo_img .img-wrap {
    overflow: hidden;
    margin-bottom: 15px;
    box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eff2f7; }
  .demo_img img {
    border-radius: 5px; }

.intro-demo_img {
  background: #fff;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  padding: 10px;
  border-radius: 10px; }
  .intro-demo_img img {
    border-radius: 5px; }
