.card {
  border: 0px;
  // border-color : $border-color;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: $shadow;
  background: $white;
  // height: calc(100% - 30px);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    background: transparent;
    padding: 20px;

    // margin      : 0px 35px;
    // @include respond("desktop") {
    //     padding: 30px;
    // }

    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 30px 0px;
    // }
  }

  &-title {
    font-size: 16px;
    margin-bottom: 0px;
    color: $headings-color;
    font-weight: 600;
  }

  &.transparent {
    background: transparent;
    box-shadow: none;
    border: 0px;

    .card-header {
      border: 0px;
    }
    .card-body {
      padding: 0px;
    }
  }

  .card-body {
    padding: 20px;
    background: transparent;
    border-radius: 15px;

    // @include respond("desktop") {
    //     padding: 30px;
    // }

    // @include custommq($min: 1200px, $max: 1350px) {
    //     padding: 30px 0px !important;
    // }
  }

  .card-footer {
    padding: 20px;
    background: $white;
  }
}
