.testimonial {
    // background: #F1F4F8;

    .slick-initialized .slick-slide {
        display: flex;
    }
}

.testimonial-content {
    background   : $white;
    box-shadow   : 0 1.5rem 4rem rgba(22, 28, 45, .05);
    border-radius: 5px;




}

.customer-img {
    img {
        border-top-left-radius   : 5px;
        border-bottom-left-radius: 5px;
        position                 : relative;

        &::before {
            position: absolute;
            content : '';
        }
    }
}

.customer-review {
    padding: 30px;

    img {
        width        : auto !important;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 0px;
    }
}

.customer-info {
    h6 {
        font-size: 18px;
    }
}



.testimonial2-content {
    box-shadow: $shadow;
    padding   : 50px 30px;
    margin    : 20px 0px;

    h3 {
        margin-bottom: 20px;
    }

    h5 {
        margin-bottom: 10px;
    }
}