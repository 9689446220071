button,
.btn {
    color      : #fff;
    &:hover,
    &:focus,
    &:active {
        // background: $white;
        color: #fff;
    }

}

.btn-outline-primary,
.btn-outline-secondary,
.btn-outline-success,
.btn-outline-info,
.btn-outline-warning,
.btn-outline-danger,
.btn-outline-light {
    color: $dark;

    &:hover,
    &:focus,
    &:active {
        color: $white;
    }
}

.btn-outline-dark {
    color: $dark;

    &:hover,
    &:focus,
    &:active {
        color: $white;
    }
}