.appss-content {
    @include respond("tab-port") {
        margin-top   : 50px;
        margin-bottom: 50px;
    }

    h2 {
        margin-bottom: 30px;
        font-weight  : 700;
    }

    ul {
        li {
            padding  : 10px 0;
            font-size: 17px;

            i {
                font-weight : bold;
                font-size   : 18px;
                color       : $primary;
                margin-right: 15px;
            }
        }
    }

    .btn {
        display         : inline-block;
        padding         : 10px 30px;
        // border-radius: 50px;
        margin-right    : 15px;
    }
}




.wallet-feature {
    // border-top: 1px solid $border-color;

    .wallet-feature-content {
        margin-bottom: 50px;
        text-align   : center;
        background   : $white;
        padding      : 50px 15px 70px;
        box-shadow   : $shadow;
        border-radius: $radius;

        i {
            font-size    : 40px;
            margin-bottom: 10px;
            color        : $primary;
            display      : inline-block;
        }

        h4 {
            // max-width: 200px;
            margin: 0 auto;
        }
    }
}

//

.all-coin {
    border-bottom: 1px solid $border-color;
}

.all-coins {
    display        : flex;
    justify-content: center;
    flex-wrap      : wrap;

    span {
        font-size      : 48px;
        // padding: 10px;
        width          : 75px;
        height         : 75px;
        // box-shadow: 0 2rem 5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.05);
        border         : 1px solid $border-color;
        border-radius  : 8px;
        text-align     : center;
        margin         : 15px;
        display        : flex;
        justify-content: center;
        align-items    : center;
    }
}

.all-coin-content {
    padding   : 75px 0 0;
    text-align: center;

    h3 {
        margin-bottom: 15px;
    }
}


.our_vision {
    h3 {
        font-size    : 24px;
        font-weight  : 500;
        margin-bottom: 28px;
    }

    p {
        font-size    : 16px;
        line-height  : 30px;
        margin-bottom: 30px;
    }
}

.about {
    // background: $white;
}

.service-img {
    img {
        border-radius: 5px;
    }
}


.info-list {
    ul {
        li {
            margin-bottom: 7px;
            color        : $body-color;
            font-weight  : 500;
            padding      : 12px 0;

            &:hover,
            &:focus {
                color: $primary;
            }

            i {
                margin-right: 15px;
                color       : $primary;
                font-weight : bold;
                font-size   : 24px;
            }
        }
    }
}


.helpdesk-search {
    // background: #F9F9F9;

    .helpdesk-search-content {
        text-align: center;
    }

    .helpdesk-form {
        form {
            display: flex;
        }

        .btn {
            margin-left: 20px;
            padding    : 6px 30px;
        }
    }
}

.help-category {
    .help-cat {
        text-align   : center;
        margin-bottom: 50px;

        span {
            font-size: 48px;
            color    : $primary;
        }

        h4 {
            font-size : 20px;
            margin-top: 15px;
        }
    }

    .sub-cat {
        display      : inline-block;
        padding      : 20px 30px;
        background   : $light;
        border-radius: 5px;
        text-align   : center;
        margin-bottom: 30px;

        h5 {
            color        : $headings-color;
            margin-bottom: 0px;
        }
    }
}

.troubleshooting-content {
    h4 {
        border-bottom : 1px solid $border-color;
        padding-bottom: 20px;
        margin-top    : 50px;
    }

    ul {
        margin-top: 20px;

        li {
            a {
                color         : $body-color;
                display       : inline-block;
                padding-bottom: 15px;

                &:hover,
                &:focus,
                &:active,
                &.active {
                    color: $primary;
                }
            }
        }
    }
}


.ticket-box {
    background: $light;
}

.ticket-box-content {
    text-align   : center;
    // padding   : 50px 15px;

    p {
        padding      : 10px 0 20px;
        margin-bottom: 0px;
        font-size    : 18px;
    }

    .btn {
        min-width  : 150px;
        font-weight: 600;
    }
}








.terms_condition {
    position     : relative;
    // background: $white;
}

.terms_condition-content {
    padding: 80px 0px 80px;
}

.terms_condition-text {
    margin-bottom: 45px;

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        font-size       : 24px;
        // font-weight  : 500;
        margin-bottom   : 30px;
    }

    p {
        font-size  : 16px;
        line-height: 30px;
        display    : inline-block;
        // color: #2E4577;
    }

    li {
        display: flex;

        i {
            line-height : 30px;
            margin-right: 15px;
            font-size   : 13px;
            color       : $dark;
        }
    }
}