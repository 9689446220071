.wallet-widget {
    background   : $white;
    padding      : 30px 15px;
    text-align   : center;
    margin-bottom: 30px;
    border-radius: 15px;

    p {
        margin-bottom: 0px;
    }
}